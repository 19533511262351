<template>
  <div class="EShopDecoration content_body">
    <div class="dis_flex flex_dir_column" style="height: 100%">
      <div class="flex_box">
        <el-tabs v-model="activeName">
          <el-tab-pane label="首页" name="0">
            <HomeDecoration ref="HomeDecoration"></HomeDecoration>
          </el-tab-pane>
          <el-tab-pane label="个人中心" name="1">
            <!-- position_relative -->
            <div class="dis_flex ">
              <div class="flex_box back_f8" >
                <el-scrollbar style="height:78vh">
                  <div class="model border back_F5">
                    <el-image style="width: 100%; height: 240px" :src="require('../../../assets/img/store/bg.png')" fit="fill"></el-image>
                    <div class="center">
                      <!-- 预约 -->
                      <div v-if="centerFormData.ShowAppointmentBill" class="appointment pad_0_10 martp_10 backfff dis_flex flex_y_center flex_x_between">
                        <div class="color_333 font_16 dis_flex flex_y_center">
                          <el-image style="width: 25px; height: 20px;margin-right:14px" :src="require('../../../assets/img/store/work1.png')" fit="fill"></el-image>
                          预约</div>
                        <div class="dis_flex flex_y_center">
                          <div class="num dis_flex flex_x_center flex_y_center">
                            
                          </div>
                          <i class="marlt_10 el-icon-arrow-right color_999"></i>
                        </div>
                      </div>
                      <!-- 团队 -->
                       <div v-if="centerFormData.ShowTeam" class="appointment pad_0_10 martp_10 backfff dis_flex flex_y_center flex_x_between">
                        <div class="color_333 font_16 dis_flex flex_y_center">
                          <el-image style="width: 24px; height: 24px;margin-right:14px" :src="require('../../../assets/img/store/work2.png')" fit="fill"></el-image>
                          团队</div>
                        <div class="dis_flex flex_y_center">
                          <i class="marlt_10 el-icon-arrow-right color_999"></i>
                        </div>
                      </div>
                      <!-- 账户存量 -->
                       <div v-if="centerFormData.ShowAccount" class="appointment pad_0_10 martp_10 backfff dis_flex flex_y_center flex_x_between">
                        <div class="color_333 font_16 dis_flex flex_y_center">
                          <el-image style="width: 23px; height: 19px;margin-right:14px" :src="require('../../../assets/img/store/work3.png')" fit="fill"></el-image>
                          账户存量</div>
                        <div class="dis_flex flex_y_center">
                          <i class="marlt_10 el-icon-arrow-right color_999"></i>
                        </div>
                      </div>
                      <!-- 减重计划 -->
                       <div v-if="centerFormData.ShowWeightPlan" class="appointment pad_0_10 martp_10 backfff dis_flex flex_y_center flex_x_between">
                        <div class="color_333 font_16 dis_flex flex_y_center">
                          <el-image style="width: 23px; height: 22px;margin-right:14px" :src="require('../../../assets/img/store/work1.png')" fit="fill"></el-image>
                          减重计划</div>
                        <div class="dis_flex flex_y_center">
                          <i class="marlt_10 el-icon-arrow-right color_999"></i>
                        </div>
                      </div>
                    </div>
                    <div class="dis_flex flex_dir_row flex_x_around pad_10 border_box border_top  position_absolute" style="background-color:white ;height:49px;bottom:0;left:0;right:0" >
                      <div class="dis_flex flex_dir_column flex_y_center color_999" >
                        <i class="el-icon-s-home font_20 " ></i>
                        <span class="font_12" >首页</span>
                      </div>
                      <div class="dis_flex flex_dir_column flex_y_center" style="color:#1E9EFA">
                        <i class="el-icon-user-solid font_20 "></i>
                        <span class="font_12 ">我的</span>
                      </div>
                    </div>

                  </div>
                </el-scrollbar>
              </div>

              <div style="width: 400px" class="pad_0_10">
                <el-scrollbar style="height:78vh">
                  <el-form ref="form" :model="centerFormData" label-width="0px">
                    <!-- <el-form-item>
                      <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                        <span>销售订单</span>
                        <el-switch v-model="centerFormData.ShowSaleBill"></el-switch>
                      </div>
                      <el-checkbox v-model="centerFormData.HideOfflineSaleBill" class="pad_0_10">是否隐藏线下销售订单</el-checkbox>

                    </el-form-item> -->

                    <!-- <el-form-item>
                      <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                        <span>消耗订单</span>
                        <el-switch v-model="centerFormData.ShowTreatBill"></el-switch>
                      </div>
                    </el-form-item> -->
                    <el-form-item>
                      <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                        <span>预约</span>
                        <el-switch v-model="centerFormData.ShowAppointmentBill"></el-switch>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                        <span>团队</span>
                        <el-switch v-model="centerFormData.ShowTeam"></el-switch>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                        <span>账户存量</span>
                        <el-switch v-model="centerFormData.ShowAccount"></el-switch>
                      </div>
                    </el-form-item>
                    <el-form-item>
                      <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                        <span>减重计划</span>
                        <el-switch v-model="centerFormData.ShowWeightPlan"></el-switch>
                      </div>
                    </el-form-item>
                    <!-- <el-form-item>
                      <div class="switchBox pad_0_10 dis_flex flex_x_between flex_y_center">
                        <span>存量信息</span>
                      </div>
                      <div  class="pad_0_10  flex_dir_column ">
                        <el-checkbox style="width:100%" v-model="centerFormData.ShowProductAccount">产品存量</el-checkbox>
                        <el-checkbox style="width:100%" v-model="centerFormData.ShowProjectAccount">项目存量</el-checkbox>
                        <el-checkbox style="width:100%" v-model="centerFormData.ShowGeneralCardAccount">通用次卡存量</el-checkbox>
                        <el-checkbox style="width:100%" v-model="centerFormData.ShowTimeCardAccount">时效卡存量</el-checkbox>
                        <el-checkbox style="width:100%" v-model="centerFormData.ShowSavingCardAccount">储值卡存量</el-checkbox>
                        <el-checkbox style="width:100%" v-model="centerFormData.ShowPackageCardAccount">套餐卡存量</el-checkbox>
                      </div>
                    </el-form-item> -->
                  </el-form>
                </el-scrollbar>
              </div>
               
            </div>
          </el-tab-pane>
          <el-tab-pane label="商品分类" name="2">
            <div class="dis_flex position_relative">
              <div class="dis_flex flex_x_center flex_box pad_10 back_f8" >
                <div class="model border classify" style="height:78vh">
                  <!-- 头部 -->
                  <div style="height:50px;line-height:50px" class="text_center backfff font_16 color_333">商品分类</div>
                  <!-- 搜索 -->
                  <div style="height:45px;" class="dis_flex flex_x_center flex_y_center">
                    <div style="height:30px;width:95%" class="backfff font_12 dis_flex  flex_y_center">
                      <i class="el-icon-search color_999 marlt_5"></i> <span class="marlt_5 color_999">搜索</span>
                    </div>
                  </div>
                  <!-- main  -->
                  <div style="height:calc(78vh - 95px);" class="dis_flex">
                    <div style="height:100%;width:25%;overflow: hidden;">
                      <div v-for="(item,index) in goodsClassify" :key="index" style="height:40px;line-height:40px" class="dis_flex oneLine flex_x_center  font_14 color_333  " :class="index==leftNavIndex ? 'backfff active ' : ''" @click="clickLeftNav(index)">{{item.Name}}</div>
                    </div>
                    <div style="height:100%;width:75%" class="backfff">
                      <el-scrollbar style="height:100%;overflow-x:hidden!important;" v-if="goodsClassify.length > 0">
                        <!-- <div v-for="(item,index) in goodsClassify" :key="index"> -->
                          <div v-for="(item,index) in goodsClassify[leftNavIndex].Child" :key="index">
                            <div style="height:40px;line-height:40px;width:90%" class="marlt_10 font_14 color_999 oneLine">{{item.Name}}</div>
                            <div v-for="(item,index) in item.Goods" :key="index">
                              <el-row>
                                <el-col :span="8">
                                  <el-image style="width: 80px; height: 80px" class="marlt_10" :src="item.ImageURL" fit="cover"></el-image>
                                </el-col>
                                <el-col :span="16">
                                  <div class="dis_flex flex_dir_column flex_x_between pad_0_10" style=" height: 80px">
                                    <el-row>
                                      <el-col :span="24">
                                        <el-tag color="#ff5510" size="mini" type="warning" effect="dark">{{item.GoodsTypeName}} </el-tag>
                                        <span class="color_333 font_14 marlt_5">{{item.Name}}</span>
                                      </el-col>
                                    </el-row>
                                    <el-row>
                                      <el-col :span="16">
                                        <div class="font_14 color_333">￥{{item.Price}}</div>
                                        <div v-if="item.OriginalText" class="font_12 color_999" style="text-decoration:line-through;">￥{{item.OriginalText}}</div>
                                      </el-col>
                                      <el-col :span="8">
                                        <div class="tag border font_14 color_999">详情</div>
                                      </el-col>
                                    </el-row>
                                  </div>
                                </el-col>
                              </el-row>
                            </div>
                          </div>
                        <!-- </div> -->
                      </el-scrollbar>

                    </div>
                  </div>
                </div>
              </div>
              <div class="marlt_20" style="width: 500px;">

                <el-form ref="form" :model="formData" class="padrt_10" label-width="0px">
                  <!-- 标题 -->
                  <el-row>
                    <el-col :span="24" class="dis_flex flex_x_between">
                      <span>商品分类</span>
                    </el-col>
                    <el-col :span="24">
                      <el-scrollbar class="el_scrollbar_height" style="height:calc(78vh - 10px)">
                        <el-col :span="24">
                          <draggable v-model="goodsClassify" chosenClass="chosen" group="people1"  animation="1000">
                            <div v-for="(item,index1) in goodsClassify" :key="index1" class="martp_10 marrt_10">
                              <el-collapse v-model="firstCollapseName" accordion>
                                <el-collapse-item :name="index1">
                                  <template slot="title">
                                    <el-col :span="6" class="text_center">
                                      <span> 分类{{index1+1}}：</span>
                                    </el-col>
                                    <el-col :span="18">
                                      <el-input size="small" style="width:200px" @click.stop.native v-model="item.Name" placeholder="标题建议2-4个子"></el-input>
                                    </el-col>
                                    <i class="el-icon-error" @click.stop="delFirstClassify(index1)"></i>
                                  </template>
                                  <el-row>
                                    <el-col :span="24" class="dis_flex flex_x_center">
                                      <draggable v-model="item.Child" chosenClass="chosen" forceFallback="true" group="people2" animation="1000">
                                        <div v-for="(item,index2) in item.Child" :key="index2" class="martp_10 classSon " style="width:430px">
                                          <el-card shadow="always" :body-style="{ padding: '0px' }">
                                            <el-collapse v-model="secondCollapseName" accordion>
                                              <el-collapse-item :name="index2">
                                                <template slot="title">
                                                  <el-col :span="6" class="text_right">
                                                    <span> 子分类{{index2+1}}：</span>
                                                  </el-col>
                                                  <el-col :span="18">
                                                    <el-input size="small" style="width:200px" @click.stop.native v-model="item.Name" placeholder="标题建议2-4个子"></el-input>
                                                  </el-col>
                                                  <i class="el-icon-error" @click.stop="delSecondClassify(index1,index2)"></i>
                                                </template>
                                                <el-row class="dis_flex flex_y_center flex_dir_column ">
                                                  <el-col :span="23">
                                                    <draggable v-model="item.Goods" chosenClass="chosen" forceFallback="true" group="people3" animation="1000">
                                                      <div class="dis_flex flex_y_center pad_10 mar_10_0 back_f7f8fa good" v-for="(item,index3) in item.Goods" :key="index3">
                                                        <el-image style="width: 48px; height: 48px" :src="item.ImageURL" fit="cover"></el-image>
                                                        <span class="marlt_10 font_13 color_333 ">{{item.Name}}</span>
                                                        <i class="el-icon-error" @click.stop="delGoods(index1,index2,index3)"></i>
                                                      </div>
                                                    </draggable>

                                                  </el-col>
                                                  <el-col :span="23" class="">
                                                    <div style="height:40px;line-height:40px" class="back_f7f8fa text_center mar_lr_center ">
                                                      <el-dropdown>
                                                        <div class="el-dropdown-link ">
                                                          <i class="el-icon-plus"></i> 添加商品
                                                        </div>
                                                        <el-dropdown-menu slot="dropdown">
                                                          <el-dropdown-item @click.native="addGoods('project',index1,index2)">项目</el-dropdown-item>
                                                          <el-dropdown-item @click.native="addGoods('product',index1,index2)">产品</el-dropdown-item>
                                                          <el-dropdown-item @click.native="addGoods('generalCard',index1,index2)">通用次卡</el-dropdown-item>
                                                          <el-dropdown-item @click.native="addGoods('timeCard',index1,index2)">时效卡</el-dropdown-item>
                                                          <el-dropdown-item @click.native="addGoods('saveCard',index1,index2)">储值卡</el-dropdown-item>
                                                          <el-dropdown-item @click.native="addGoods('packageCard',index1,index2)">套餐卡</el-dropdown-item>
                                                        </el-dropdown-menu>
                                                      </el-dropdown>
                                                    </div>
                                                  </el-col>
                                                </el-row>
                                              </el-collapse-item>
                                            </el-collapse>
                                          </el-card>
                                        </div>
                                      </draggable>
                                    </el-col>
                                    <el-col :span="24" class="martp_10 dis_flex flex_x_center">
                                      <el-card shadow="always" :body-style="{ padding: '0px' }">
                                        <div style="height:50px;width:430px;background:#fff;line-height:50px" class="text_center mar_lr_center " @click="addSecondClassify(index1)">添加子分类</div>
                                      </el-card>
                                    </el-col>
                                  </el-row>
                                </el-collapse-item>
                              </el-collapse>
                            </div>
                          </draggable>
                        </el-col>
                        <el-col :span="24">
                          <div style="height:60px;line-height:60px" class="back_f7f8fa text_center martp_10 font_14 " @click="addFirstClassify"><i class="el-icon-plus"></i> 添加主分类</div>
                        </el-col>
                      </el-scrollbar>
                    </el-col>

                  </el-row>
                </el-form>

              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
        <div class="configBtn">
          <!-- <el-button size="small" type="danger" @click="GoodsCategoryAll">撤回</el-button> -->
          <el-button size="small" type="primary" @click="SaveEShopDecorationConf"> 保 存 </el-button>
        </div>
      </div>
      <!-- <div style="height: 50px" class="dis_flex flex_x_center flex_y_center">
        <el-button size="small" @click="getConfigInfo">取消</el-button>
        <el-button size="small" type="primary" @click="Create"
          >保存并应用</el-button
        >
      </div> -->
    </div>
    <!-- 上传轮播图弹出层 -->
    <el-dialog title="上传banner" :visible.sync="BannerDialog" @close="close" width="45%">
      <el-form size="small" ref="bannerForm" :model="bannerForm" label-width="80px">
        <el-form-item label="图片展示">
          <el-image v-if="bannerForm.ImageUrl" style="width: 210px; height: 120px" :src="bannerForm.ImageUrl" fit="fill"></el-image>
          <el-upload class="upload-demo" action="#" :show-file-list="false" :before-upload="beforeAvatarUpload" :http-request="updateCustomerUploadImage">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过500kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="配置链接">
          <el-select v-model="bannerForm.TypeID" placeholder="请选择">
            <el-option label="请选择网店商品" :value="1"></el-option>
            <el-option label="请选择拼团商品" :value="2"></el-option>
            <el-option label="请选择秒杀商品" :value="3"></el-option>
          </el-select>
          <el-button style="margin-left: 10px" type="primary" v-if="bannerForm.TypeID && !bannerForm.name" @click="selGoodsBtn">{{ selGoodName }}</el-button>
        </el-form-item>
        <el-form-item v-if="bannerForm.name" label="商品名称">
          {{ bannerForm.name }}
          <el-button style="margin-left: 10px" type="danger" @click="delGoodsBtn">删除</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="BannerDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="bannerSave">保存</el-button>
      </span>
    </el-dialog>
    <!-- 轮播图活动弹出层 -->
    <el-dialog :title="Title" :visible.sync="activityDialog" @close="close" width="90%">
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="6">
          <el-form :inline="true" size="small" class="demo-form-inline" @submit.native.prevent @keyup.enter.native="Search">
            <el-form-item label="线上名称">
              <el-input v-model="OnlineName" @clear="Search" clearable placeholder="输入商品线上名称搜索"></el-input>
            </el-form-item>
            <!-- <el-form-item label="商品类型">
              <el-select
                @change="activeSearch"
                v-model="GoodsType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in GoodType"
                  :key="index"
                  :label="item.Name"
                  :value="item.GoodsTypeID"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="small" type="primary" @click="Search">搜索</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-scrollbar class="el_scrollbar_height active">
        <el-table :data="activeTableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="ID" label="主图缩略">
            <template slot-scope="scope">
              <el-image style="width: 35px; height: 35px" :src="scope.row.ImgURL" fit="fit">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="Title" label="线上别名"></el-table-column>
          <el-table-column prop="OfflineType" label="线下类型"></el-table-column>
          <el-table-column prop="activeTime" label="线下名称"></el-table-column>
          <el-table-column :formatter="formatterTime" label="活动时间"></el-table-column>
          <el-table-column label="操作" width="120px">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="selGoods(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end">
        <el-pagination background :current-page.sync="paginations.page" :layout="paginations.layout" :total="paginations.total" @current-change="pageChange"></el-pagination>
      </div>
    </el-dialog>
    <!-- 轮播图商品弹出层 -->
    <el-dialog :title="Title" :visible.sync="GoodsDialog" @close="close" width="90%">
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" class="demo-form-inline" @keyup.enter.native="Search">
            <el-form-item label="线上名称">
              <el-input v-model="OnlineName" @clear="Search" clearable placeholder="输入商品线上名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="线下名称">
              <el-input v-model="OfflineName" @clear="Search" clearable placeholder="输入商品线下名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select @change="Search" v-model="GoodsType" placeholder="请选择" clearable>
                <el-option v-for="(item, index) in GoodType" :key="index" :label="item.Name" :value="item.GoodsTypeID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="线上分类">
              <el-cascader clearable v-model="CategoryID" :options="GoodsCategoryValid" :props="{ expandTrigger: 'hover' }" @change="Search"></el-cascader>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="small" type="primary" @click="Search">搜索</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-scrollbar class="el_scrollbar_height active">
        <el-table :data="GoodstableData" tooltip-effect="dark" style="width: 100%">
          <el-table-column label="主图缩略">
            <template slot-scope="scope">
              <el-image style="width: 35px; height: 35px" :src="scope.row.Image[0].ImgURL" fit="fit">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="OnlineName" label="线上别名"></el-table-column>
          <el-table-column prop="Price" label="售价（元）"></el-table-column>
          <el-table-column prop="CategoryName" label="线上分类"></el-table-column>
          <el-table-column prop="Sequence" label="线上排序"></el-table-column>
          <el-table-column prop="GoodsTypeName" label="线下类型"></el-table-column>
          <el-table-column prop="OfflineName" label="线下名称"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button size="small" type="primary" @click="selGoods(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end">
        <el-pagination background :current-page.sync="paginations.page" :layout="paginations.layout" :total="paginations.total" @current-change="pageChange"></el-pagination>
      </div>
    </el-dialog>
    <!-- 拼团秒杀弹出层 -->
    <el-dialog :title="Title" :visible.sync="PMDialog" @close="close" width="70%">
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="12">
          <el-form :inline="true" size="small" class="demo-form-inline" @submit.native.prevent @keyup.enter.native="Search">
            <el-form-item label="线上名称">
              <el-input v-model="OnlineName" @clear="Search" clearable placeholder="输入商品线上名称搜索"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="Search">搜索</el-button>
            </el-form-item>
            <!-- <el-form-item label="商品类型">
              <el-select
                @change="activeSearch"
                v-model="GoodsType"
                placeholder="请选择"
                clearable
              >
                <el-option
                  v-for="(item, index) in GoodType"
                  :key="index"
                  :label="item.Name"
                  :value="item.GoodsTypeID"
                ></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </el-col>
        <!-- <el-col :span="2">
          <el-button size="small" type="primary" @click="Search"
            >搜索</el-button
          >
        </el-col> -->
      </el-row>
      <!-- 表格 -->
      <el-scrollbar class="el_scrollbar_height active">
        <el-table ref="PMTableRef" :row-key="rowKeyFun" :data="activeTableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column :reserve-selection="true" type="selection" width="55">
          </el-table-column>
          <el-table-column label="主图缩略">
            <template slot-scope="scope">
              <el-image style="width: 35px; height: 35px" :src="scope.row.ImgURL" fit="fit">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="Title" label="线上别名"></el-table-column>
          <el-table-column prop="OfflineType" label="线下类型"></el-table-column>
          <el-table-column prop="OfflineName" label="线下名称"></el-table-column>
          <el-table-column prop="activeTime" label="线下名称"></el-table-column>
        </el-table>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end">
        <el-pagination background :current-page.sync="paginations.page" :layout="paginations.layout" :total="paginations.total" @current-change="pageChange"></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="PMDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="savePM">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 热门推荐弹出层 -->
    <el-dialog :title="Title" :visible.sync="RmDialog" @close="close" width="90%">
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" class="demo-form-inline" @keyup.enter.native="Search">
            <el-form-item label="线上名称">
              <el-input v-model="OnlineName" @clear="Search" clearable placeholder="输入商品线上名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="线下名称">
              <el-input v-model="OfflineName" @clear="Search" clearable placeholder="输入商品线下名称搜索"></el-input>
            </el-form-item>
            <el-form-item label="商品类型">
              <el-select @change="Search" v-model="GoodsType" placeholder="请选择" clearable>
                <el-option v-for="(item, index) in GoodType" :key="index" :label="item.Name" :value="item.GoodsTypeID"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="线上分类">
              <el-cascader clearable v-model="CategoryID" :options="GoodsCategoryValid" :props="{ expandTrigger: 'hover' }" @change="Search"></el-cascader>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2">
          <el-button size="small" type="primary" @click="Search">搜索</el-button>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-scrollbar class="el_scrollbar_height active">
        <el-table ref="multipleTable" :row-key="rowKeyFun" :data="GoodstableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column :reserve-selection="true" width="55" type="selection"></el-table-column>
          <el-table-column label="主图缩略">
            <template slot-scope="scope">
              <el-image style="width: 35px; height: 35px" :src="scope.row.Image[0].ImgURL" fit="fill">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="OnlineName" label="线上别名"></el-table-column>
          <el-table-column prop="Price" label="售价（元）"></el-table-column>
          <el-table-column prop="CategoryName" label="线上分类"></el-table-column>
          <el-table-column prop="Sequence" label="线上排序"></el-table-column>
          <el-table-column prop="GoodsTypeName" label="线下类型"></el-table-column>
          <el-table-column prop="OfflineName" label="线下名称"></el-table-column>
        </el-table>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end">
        <el-pagination background :current-page.sync="paginations.page" :layout="paginations.layout" :total="paginations.total" @current-change="pageChange"></el-pagination>
      </div>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="RmDialog = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveRecommend">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 商品分类添加商品弹出层 -->
    <el-dialog :title="goodsClassifyTitle" :visible.sync="goodsClassifyBool" @close="close" width="60%">
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="22">
          <el-form :inline="true" size="small" class="demo-form-inline" label-width="40px" @submit.native.prevent>
            <el-form-item label="名称">
              <el-input v-model="goodSearchName" @clear="Search" clearable placeholder="输入商品线上名称搜索"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="Search('goodClassifySearch')">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <!-- 表格 -->
      <el-scrollbar class="el_scrollbar_height active">
        <el-table ref="goodsClassify" size="small" :row-key="(row)=>row.ID" :data="GoodsCategoryGoodList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column :reserve-selection="true" width="55" type="selection"></el-table-column>
          <el-table-column label="主图缩略">
            <template slot-scope="scope">
              <el-image style="width: 35px; height: 35px" :src="scope.row.ImageURL" fit="cover"> </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="Name" label="商品名称"></el-table-column>
          <el-table-column prop="Price" label="售价（元）"></el-table-column>
          <el-table-column v-if="goodsClassifyTitle=='项目列表' || goodsClassifyTitle=='产品列表'" prop="OriginalText" label="划线价（元）"></el-table-column>
          <el-table-column v-if="goodsClassifyTitle=='通用次卡列表'" prop="Amount" label="次数"></el-table-column>
          <el-table-column v-if="goodsClassifyTitle=='时效卡列表'" prop="ConsumeCycle" :formatter="formatterTimeCardDay" label="消耗周期(天)"></el-table-column>
          <el-table-column v-if="goodsClassifyTitle=='时效卡列表'" prop="CycleLimitAmount" :formatter="formatterTimeCardNum" label="周期次数"></el-table-column>
          <el-table-column v-if="goodsClassifyTitle=='时效卡列表'" prop="PerformanceAmount" label="消耗提成次数"></el-table-column>
          <el-table-column v-if="goodsClassifyTitle=='储值卡列表'" prop="LargessPrice" label="赠送金额"></el-table-column>
          <el-table-column v-if="goodsClassifyTitle!='项目列表' && goodsClassifyTitle!='产品列表'" prop="ValidDayName" label="有效期"></el-table-column>
          <el-table-column prop="GoodsTypeName" label="商品类型"></el-table-column>
        </el-table>
      </el-scrollbar>
      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end">
        <el-pagination background :current-page.sync="paginations.page" :layout="paginations.layout" :total="paginations.total" @current-change="pageChange('goodsClassify')"></el-pagination>
      </div>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="goodsClassifyBool = false">取 消</el-button>
        <el-button size="small" type="primary" @click="saveGoodsClassify">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/Marketing/EShopManage/EShopDecoration";
import dateTime from "@/components/js/date";
import draggable from "vuedraggable";
import Employee from "../../KHS/Entity/employee.vue";
import HomeDecoration from "../../Marketing/Components/HomeDecoration.vue";
export default {
  name: "EShopDecoration",
  /**  引入的组件  */
  components: {
    draggable,
    HomeDecoration,
  },
  /**  Vue 实例的数据对象**/

  data() {
    return {
      activeName: "0", //tabs索引
      formData: {
        HomeSwitchSite: true, //切换门店
        HomeBanner: true, //轮播图
        HomeGroupBooking: true, //拼团
        HomeSeckill: true, //秒杀
        HomeSite: true, //门店
        HomeEmployee: false, //员工
        HomeHotRecommended: true, //热门
        bannerList: [],
        storesInfo: {
          shopImg:
            "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1479688678,872710822&fm=26&gp=0.jpg",
          logo: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimgqn.koudaitong.com%2Fupload_files%2F2015%2F05%2F03%2F143065679990043861.jpg&refer=http%3A%2F%2Fimgqn.koudaitong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1622355186&t=64e3e354ea89ea4e2ebe89526881c03e",
        },
      },
      centerFormData: {
        ShowSaleBill: true, //是否显示销售订单
        HideOfflineSaleBill: true,//不展示线下销售订单
        ShowTreatBill: true,//是否显示消耗订单
        ShowAppointmentBill: true,//是否显示预约单
        ShowProductAccount: true,//是否显示产品存量
        ShowProjectAccount: true,//是否显示项目存量
        ShowGeneralCardAccount: true,//是否显示通用次卡存量
        ShowTimeCardAccount: true,//是否显示时效卡存量
        ShowSavingCardAccount: true,//是否显示储值卡存量
        ShowPackageCardAccount: true,//是否显示套餐卡存量
        ShowStock:true
      },
      tabBarFormData: {
        switchHome: true,
        switchClassify: true,
        switchAppointment: true,
        switchShopingCart: true,
        switchMyWork: true,
      },

      tableData: [], //拼团
      seckillTableData: [], //秒杀
      hotTableData: [], //热门
      goodsClassifyTableData: [], //商品分类新增商品列表
      rmImgs: [
        {
          src: "https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=109607329,143782259&fm=11&gp=0.jpg",
        },
      ],

      DialogType: "", //弹出层类型：轮播图\选择拼团，秒杀
      Title: "", //弹出层标题
      BannerDialog: false, //轮播
      bannerForm: {
        TypeID: "",
        ActivityID: "",
        GoodsID: "",
        name: "",
        ImageUrl: "",
      },
      activityDialog: false, //拼团秒杀弹出层
      GoodsDialog: false, //商品
      PMDialog: false, //拼团秒杀弹出层
      RmDialog: false, //商品
      paginations: {
        total: 20,
        layout: " prev, pager, next, jumper, ->, total, slot",
        page: 1,
      },
      OfflineName: "", //线下
      OnlineName: "", //线上
      GoodsType: "", //分类ID
      CategoryID: "", //线上分类
      GoodType: [], //分类列表
      GoodsCategoryValid: [], //线上分类
      GoodstableData: [], //商品列表
      activeTableData: [], //秒杀，拼团
      multipleChoice: [], //多选
      goodsClassify: [], //商品分类

      goodSearchName: "",
      GoodsCategoryGoodList: [], //商品列表
      goodsClassifyBool: false, //商品列表弹出层
      goodsClassifyTitle: "", //商品列表弹出层标题
      addGoodsIndex: [], //点击添加商品按钮索引
      leftNavIndex:0,
      firstCollapseName: 0,
      secondCollapseName: 0,
    };
  },
  /**计算属性  */
  computed: {
    selGoodName: function () {
      switch (this.bannerForm.TypeID) {
        case 1:
          return "选择网店商品";
          break;
        case 2:
          return "选择拼团商品";
          break;
        case 3:
          return "选择秒杀商品";
          break;
      }
    },
    groupBooking: function () {
      if (this.tableData.length) {
        const item = this.tableData.filter((item, index) => index <= 1);
        if (item.length) {
          item.forEach((good) => {
            if (good.Price) {
              const str = good.Price.toString();
              const index = str.indexOf(".");
              if (index != -1) {
                const arr = str.split(".").map(parseFloat);
                good.PriceInt = arr[0];
                good.PriceFlo = arr[1];
              } else {
                good.PriceInt = good.Price;
                good.PriceFlo = "00";
              }
            }
          });
        }

        return item;
      }
    },
    seckill: function () {
      if (this.seckillTableData.length) {
        const item = this.seckillTableData.filter((item, index) => index <= 1);
        if (item.length) {
          item.forEach((good) => {
            if (good.Price) {
              const str = good.Price.toString();
              const index = str.indexOf(".");
              if (index != -1) {
                const arr = str.split(".").map(parseFloat);
                good.PriceInt = arr[0];
                good.PriceFlo = arr[1];
              } else {
                good.PriceInt = good.Price;
                good.PriceFlo = "00";
              }
            }
          });
          return item;
        }
      }
    },
    hotGoods: function () {
      if (this.hotTableData.length) {
        const item = this.hotTableData.filter((item, index) => index <= 1);
        if (item.length) {
          item.forEach((good) => {
            if (good.Price) {
              const str = good.Price.toString();
              const index = str.indexOf(".");
              if (index != -1) {
                const arr = str.split(".").map(parseFloat);
                good.PriceInt = arr[0];
                good.PriceFlo = arr[1];
              } else {
                good.PriceInt = good.Price;
                good.PriceFlo = "00";
              }
            }
          });
          return item;
        }
      }
    },
    selGoodID: function () {
      const arr = [];
      this.goodsClassify.forEach((item) => {
        if (item.Child.length) {
          item.Child.forEach((item) => {
            if (item.Goods.length) {
              item.Goods.forEach((item) => {
                arr.push(item.ID);
              });
            }
          });
        }
      });
      return arr;
    },
    GoodList: function () {
      return this.GoodsCategoryGoodList.filter((item) => {
        console.log(this.selGoodID);
      });
    },
  },
  /**  方法集合  */
  methods: {
    // 获取配置详情
    getConfigInfo() {
      const that = this;
      API.All().then((res) => {
        if (res.StateCode == 200) {
          const Data = res.Data;
          const formData = {
            HomeSwitchSite: Data.HomeSwitchSite, //切换门店
            HomeBanner: Data.HomeBanner, //轮播图
            HomeGroupBooking: Data.HomeGroupBooking, //拼团
            HomeSeckill: Data.HomeSeckill, //秒杀
            HomeSite: Data.HomeSite, //门店
            HomeEmployee: Data.HomeEmployee, //员工
            HomeHotRecommended: Data.HomeHotRecommended, //热门
            bannerList: Data.Banner,
            storesInfo: {
              shopImg:
                "https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=1479688678,872710822&fm=26&gp=0.jpg",
              logo: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimgqn.koudaitong.com%2Fupload_files%2F2015%2F05%2F03%2F143065679990043861.jpg&refer=http%3A%2F%2Fimgqn.koudaitong.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1622355186&t=64e3e354ea89ea4e2ebe89526881c03e",
            },
          };
          const centerFormData = {
            switchCoupon: Data.CenterCoupon,
            switchOrder: Data.CenterBill,
            switchAppointment: Data.CenterAppointment,
            switchStock: Data.CenterAccount,
          };
          const tabBarFormData = {
            switchHome: Data.BarHomePage,
            switchClassify: Data.BarCategory,
            switchAppointment: Data.BarAppointment,
            switchShopingCart: Data.BarCar,
            switchMyWork: Data.BarPersonCenter,
          };
          const tableData = [];
          Data.GroupBooking.forEach((item) => {
            tableData.push({
              GroupBookingID: item.GroupBookingID,
              GoodsID: item.GoodsID,
              Title: item.Name,
              GoodType: item.GoodType,
              EndDate: item.EndDate,
              GoodType: item.GoodType,
              ImgURL: item.ImgURL,
              Price: item.Price,
              OldPrice: item.OldPrice,
              Price: item.Price,
              OldPrice: item.OldPrice,
            });
          });
          const seckillTableData = [];
          Data.Seckill.forEach((item) => {
            seckillTableData.push({
              SeckillID: item.SeckillID,
              GoodsID: item.GoodsID,
              Title: item.Name,
              StartDate: item.StartDate,
              EndDate: item.EndDate,
              GoodType: item.GoodType,
              ImgURL: item.ImgURL,
            });
          });
          const hotTableData = [];
          Data.HotRecommended.forEach((item) => {
            hotTableData.push({
              GoodsID: item.GoodsID,
              OnlineName: item.Name,
              OfflineName: item.OfflineName,
              GoodsTypeName: item.GoodsType,
              ImgURL: item.ImgURL,
              Coupon: item.Coupon,
              Price: item.Price,
              OldPrice: item.OldPrice,
            });
          });
          that.formData = formData;
          that.centerFormData = centerFormData;
          that.tabBarFormData = tabBarFormData;

          that.tableData = tableData;
          that.seckillTableData = seckillTableData;
          that.hotTableData = hotTableData;
        }
      });
    },
    // 获取秒杀列表
    getGetSecKill() {
      const that = this;
      that.activeTableData = [];
      let params = {
        PageNum: that.paginations.page,
      };
      if (that.OnlineName) {
        params = {
          Title: that.OnlineName,
          PageNum: that.paginations.page,
        };
      }
      API.GetSecKill(params).then((res) => {
        if (res.StateCode == 200) {
          that.activeTableData = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    formatterTime(row) {
      return row.StartDate + " " + "至" + " " + row.EndDate;
    },
    // 获取拼团列表
    getGetBookingList() {
      const that = this;
      that.activeTableData = [];
      let params = {
        PageNum: that.paginations.page,
      };
      if (that.OnlineName) {
        params = {
          Title: that.OnlineName,
          PageNum: that.paginations.page,
        };
      }
      API.GetBookingList(params).then((res) => {
        if (res.StateCode == 200) {
          that.activeTableData = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取网店商品列表
    getGoodAll() {
      const params = {
        OfflineName: this.OfflineName,
        GoodsType: this.GoodsType,
        OnlineName: this.OnlineName,
        CategoryID: this.CategoryID ? this.CategoryID[1] : "",
        Active: true,
        PageNum: this.paginations.page,
      };
      API.GoodAll(params).then((res) => {
        if (res.StateCode == 200) {
          this.GoodstableData = res.List;
          this.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取商品类型
    getGoodType() {
      API.GoodType().then((res) => {
        if (res.StateCode == 200) {
          this.GoodType = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 线上分类 - 排除没有子类的分类
    getAppletGoodsCategoryValid() {
      API.AppletGoodsCategoryValid().then((res) => {
        if (res.StateCode == 200) {
          const arr = [];
          res.Data.forEach((res) => {
            const obj = {
              label: res.Name,
              value: res.CategoryID,
              children: [],
            };
            if (res.Child && res.Child.length) {
              res.Child.forEach((res) => {
                obj.children.push({
                  label: res.Name,
                  value: res.CategoryID,
                });
              });
            }

            arr.push(obj);
          });
          this.GoodsCategoryValid = arr;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 点击上传轮播
    uploadBtn() {
      const that = this;
      if (that.formData.bannerList.length >= 5) {
        that.$message.error({
          message: "上传图片不能超过5张",
          duration: 2000,
        });
        return;
      }
      that.BannerDialog = true;
      this.DialogType = "banner";
    },
    // 点击选择商品按钮
    selGoodsBtn() {
      const that = this;
      switch (that.bannerForm.TypeID) {
        case 1:
          that.GoodsDialog = true;
          that.Title = "商品列表";
          that.getGoodAll();
          break;
        case 2:
          that.activityDialog = true;
          that.Title = "拼团列表";
          that.getGetBookingList();
          break;
        case 3:
          that.activityDialog = true;
          that.Title = "秒杀列表";
          that.getGetSecKill();
          break;
      }
    },
    // 选择商品
    selGoods(row) {
      const that = this;
      switch (that.bannerForm.TypeID) {
        case 1:
          that.GoodsDialog = false;
          that.Title = "";
          that.bannerForm.name = row.OnlineName;
          that.bannerForm.GoodsID = row.GoodsID;
          that.bannerForm.ActivityID = null;
          break;
        case 2:
          that.activityDialog = false;
          that.Title = "";
          that.bannerForm.name = row.Title;
          that.bannerForm.GoodsID = row.CommodityID;
          that.bannerForm.ActivityID = row.GroupBookingID;
          break;
        case 3:
          that.activityDialog = false;
          that.Title = "";
          that.bannerForm.name = row.Title;
          that.bannerForm.GoodsID = row.CommodityID;
          that.bannerForm.ActivityID = row.SeckillID;
          break;
      }
    },
    // 删除商品
    delGoodsBtn() {
      const that = this;
      that.bannerForm.name = "";
      that.bannerForm.GoodsID = null;
      that.bannerForm.ActivityID = null;
    },
    // banner保存
    bannerSave() {
      const that = this;
      if (!that.bannerForm.ImageUrl) {
        that.$message.error({
          message: "请选择图片",
          duration: 2000,
        });
        return;
      }
      if (!that.bannerForm.TypeID) {
        that.$message.error({
          message: "请选择配置链接",
          duration: 2000,
        });
        return;
      }
      if (!that.bannerForm.ActivityID && !that.bannerForm.GoodsID) {
        that.$message.error({
          message: "请选择关联项目",
          duration: 2000,
        });
        return;
      }
      const params = {
        TypeID: that.bannerForm.TypeID,
        ActivityID: that.bannerForm.ActivityID,
        GoodsID: that.bannerForm.GoodsID,
        ImageUrl: that.bannerForm.ImageUrl,
      };
      that.formData.bannerList.push(params);
    },
    // 轮播图左移
    banMoveLeft(index) {
      console.log(12, index);
      const that = this;
      const obj = that.formData.bannerList[index - 1];
      that.$set(
        that.formData.bannerList,
        index - 1,
        that.formData.bannerList[index]
      );
      that.$set(that.formData.bannerList, index, obj);
      that.formData.bannerList = that.formData.bannerList;
    },
    // 轮播图右移
    banMoveRight(index) {
      const that = this;
      const obj = that.formData.bannerList[index + 1];
      that.$set(
        that.formData.bannerList,
        index + 1,
        that.formData.bannerList[index]
      );
      that.$set(that.formData.bannerList, index, obj);
    },
    // 轮播图删除
    banDelete(index) {
      this.formData.bannerList.splice(index, 1);
    },
    // 搜索
    Search(e) {
      const that = this;
      switch (e) {
        case "goodClassifySearch":
          // 获取分类商品列表
          this.paginations.page = 1;
          let apiType = "";
          switch (that.goodsClassifyTitle) {
            case "项目列表":
              apiType = "GoodsCategoryProject";
              break;
            case "产品列表":
              apiType = "GoodsCategoryProduct";
              break;
            case "通用次卡列表":
              apiType = "GoodsCategoryGeneralCard";
              break;
            case "时效卡列表":
              apiType = "GoodsCategoryTimeCard";
              break;
            case "储值卡列表":
              apiType = "GoodsCategorySavingCard";
              break;
            case "套餐卡列表":
              apiType = "GoodsCategoryPackageCard";
              break;
          }
          that.GoodsCategoryGood(apiType);
          break;
      }
      return;
      this.paginations.page = 1;
      if (this.Title == "拼团列表") {
        this.getGetBookingList();
      } else if (this.Title == "秒杀列表") {
        this.getGetSecKill();
      } else if (this.Title == "商品列表") {
        this.getGoodAll();
      }
    },
    // 活动列表搜索
    // 页码
    pageChange(type) {
      const that = this;
      switch (type) {
        case "goodsClassify":
          // 获取分类商品列表
          let apiType = "";
          switch (that.goodsClassifyTitle) {
            case "项目列表":
              apiType = "GoodsCategoryProject";
              break;
            case "产品列表":
              apiType = "GoodsCategoryProduct";
              break;
            case "通用次卡列表":
              apiType = "GoodsCategoryGeneralCard";
              break;
            case "时效卡列表":
              apiType = "GoodsCategoryTimeCard";
              break;
            case "储值卡列表":
              apiType = "GoodsCategorySavingCard";
              break;
            case "套餐卡列表":
              apiType = "GoodsCategoryPackageCard";
              break;
          }
          that.GoodsCategoryGood(apiType);
          break;
      }
      return;
      console.log(11);
      if (this.Title == "拼团列表") {
        this.getGetBookingList();
      } else if (this.Title == "秒杀列表") {
        this.getGetSecKill();
      } else if (this.Title == "商品列表") {
        this.getGoodAll();
      }
    },
    // 上传图片
    updateCustomerUploadImage(base64) {
      let that = this;
      let params = {
        ImgURL: base64,
      };
      API.AddImage(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.bannerForm.ImageUrl = res.Message;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {});
    },
    beforeAvatarUpload(file) {
      let that = this;
      let isFileType = false;
      if (
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "image/jpeg"
      ) {
        isFileType = true;
      }
      const isLt2M = file.size / 1024 <= 500;

      if (!isFileType) {
        this.$message.error("上传图片只能是 JPG 格式!");
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 500kb!");
        return false;
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.updateCustomerUploadImage(base64);
      };
      return false;
    },
    // 点击拼团
    getGroupBooking() {
      const that = this;
      that.PMDialog = true;
      that.Title = "拼团列表";
      that.getGetBookingList();
    },
    // 点击秒杀
    SecKill() {
      const that = this;
      that.PMDialog = true;
      that.Title = "秒杀列表";
      that.getGetSecKill();
    },
    // 点击推荐
    recommend() {
      const that = this;
      that.RmDialog = true;
      that.Title = "商品列表";
      that.getGoodAll();
    },

    // 保存活动
    savePM() {
      const that = this;
      if (that.Title == "拼团列表") {
        if (!that.multipleChoice.length) {
          that.$message.error({
            message: " 请选择拼团项目",
            duration: 2000,
          });
          return;
        }
        that.tableData = that.multipleChoice;
      }
      if (that.Title == "秒杀列表") {
        if (!that.multipleChoice.length) {
          that.$message.error({
            message: " 请选择秒杀项目",
            duration: 2000,
          });
          return;
        }
        that.seckillTableData = that.multipleChoice;
      }
      that.PMDialog = false;
    },
    fmtTime(row) {
      return (
        dateTime.formatDate.format(
          new Date(row.StartDate),
          "YYYY-DD-MM hh:mm:ss"
        ) +
        " " +
        "至" +
        " " +
        dateTime.formatDate.format(new Date(row.EndDate), "YYYY-DD-MM hh:mm:ss")
      );
    },

    // 保存推荐
    saveRecommend() {
      const that = this;
      if (!that.multipleChoice.length) {
        that.$message.error({
          message: " 请选择推荐项目",
          duration: 2000,
        });
        return;
      }
      that.hotTableData = that.multipleChoice;
      that.RmDialog = false;
    },
    rowKeyFun(row) {
      if (this.Title == "秒杀列表") {
        return row.SeckillID;
      } else if (this.Title == "拼团列表") {
        return row.GroupBookingID;
      } else {
        return row.GoodsID;
      }
    },
    // 多选
    handleSelectionChange(e) {
      this.multipleChoice = e;
    },
    // 删除
    Delete(type, index) {
      if (type == "pt") {
        this.tableData.splice(index, 1);
      } else if (type == "ms") {
        this.seckillTableData.splice(index, 1);
      } else if (type == "rm") {
        this.hotTableData.splice(index, 1);
      }
    },
    // 关闭弹出层
    close() {
      this.$refs.goodsClassify.clearSelection();
      this.goodsClassifyTitle = "";
      this.goodSearchName = "";
      this.paginations.page = 1;
      return;
      if (this.Title == "商品列表" && this.$refs.multipleTable) {
        this.$refs.multipleTable.clearSelection();
      } else if (this.$refs.PMTableRef) {
        this.$refs.PMTableRef.clearSelection();
      }
      if (this.Title == "上传banner") {
        this.bannerForm = {
          TypeID: "",
          ActivityID: "",
          GoodsID: "",
          name: "",
          ImageUrl: "",
        };
      }
      this.paginations.page = 1;
      this.multipleChoice = [];
      this.OfflineName = ""; //线下
      this.OnlineName = ""; //线上
      this.GoodsType = ""; //分类ID
      this.CategoryID = ""; //线上分类
    },
    // 保存商城配置
    Create() {
      const that = this;
      const formData = that.formData; //首页
      const centerFormData = this.centerFormData; //个人中心
      const tabBarFormData = this.tabBarFormData;
      const GroupBooking = [];
      const Seckill = [];
      const HotRecommended = [];
      const banner = that.formData.bannerList;
      console.log(that.tableData);
      that.tableData.forEach((item) => {
        const obj = {
          ID: item.GroupBookingID,
          GoodsID: item.CommodityID ? item.CommodityID : item.GoodsID,
        };
        GroupBooking.push(obj);
      });
      console.log(that.seckillTableData);
      that.seckillTableData.forEach((item) => {
        const obj = {
          ID: item.SeckillID,
          GoodsID: item.CommodityID ? item.CommodityID : item.GoodsID,
        };
        Seckill.push(obj);
      });
      console.log(that.hotTableData);

      that.hotTableData.forEach((item) => {
        const obj = {
          GoodsID: item.GoodsID,
        };
        HotRecommended.push(obj);
      });

      const params = {
        HomeSwitchSite: formData.HomeSwitchSite,
        HomeBanner: formData.HomeBanner,
        HomeGroupBooking: formData.HomeGroupBooking,
        HomeSeckill: formData.HomeSeckill,
        HomeSite: formData.HomeSite,
        HomeEmployee: formData.HomeEmployee,
        HomeHotRecommended: formData.HomeHotRecommended,
        CenterCoupon: centerFormData.switchCoupon,
        CenterBill: centerFormData.switchOrder,
        CenterAppointment: centerFormData.switchAppointment,
        CenterAccount: centerFormData.switchStock,
        BarHomePage: tabBarFormData.switchHome,
        BarCategory: tabBarFormData.switchClassify,
        BarAppointment: tabBarFormData.switchAppointment,
        BarCar: tabBarFormData.switchShopingCart,
        BarPersonCenter: tabBarFormData.switchMyWork,
        GroupBooking: GroupBooking,
        Seckill: Seckill,
        HotRecommended: HotRecommended,
        Banner: banner,
      };
      API.Create(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "配置成功",
            duration: 2000,
          });
        } else {
          that.$message.success({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    /**    */
    changeShwoStock(val){
      let that = this
      that.centerFormData.ShowStock = val
      that.centerFormData.ShowProductAccount = val;      //是否显示产品存量
      that.centerFormData.ShowProjectAccount = val;      //是否显示项目存量
      that.centerFormData.ShowGeneralCardAccount = val;  //是否显示通用次卡存量
      that.centerFormData.ShowTimeCardAccount = val;     //是否显示时效卡存量
      that.centerFormData.ShowSavingCardAccount = val;   //是否显示储值卡存量
      that.centerFormData.ShowPackageCardAccount = val;  //是否显示套餐卡存量
    },

    // -----------商品分类-------------------
    // 添加主分类按钮
    addFirstClassify() {
      const that = this;
      const obj = {
        Name: "",
        Child: [
          {
            Name: "",
            Goods: [],
          },
        ],
      };
      that.goodsClassify.push(obj);
    },
    // 添加子分类按钮
    addSecondClassify(index) {
      console.log(index);
      const that = this;
      const obj = {
        Name: "",
        Goods: [],
      };
      that.goodsClassify[index].Child.push(obj);
    },
    // 删除主分类
    delFirstClassify(index) {
      const that = this;
      that.goodsClassify.splice(index, 1);
    },
    // 删除子分类
    delSecondClassify(index1, index2) {
      const that = this;
      that.goodsClassify[index1].Child.splice(index2, 1);
    },
    //删除商品
    delGoods(index1, index2, index3) {
      this.goodsClassify[index1].Child[index2].Goods.splice(index3, 1);
    },
    // 获取分类商品列表
    GoodsCategoryGood(apiType) {
      const that = this;
      const params = {
        PageNum: that.paginations.page,
        Name: that.goodSearchName,
      };
      API[apiType](params).then((res) => {
        if (res.StateCode == 200) {
          that.GoodsCategoryGoodList = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 打开商品列表弹出层
    addGoods(type, index1, index2) {
      const that = this;
      that.goodsClassifyBool = true;
      that.addGoodsIndex = [index1, index2];
      // 获取分类商品列表
      let apiType = "";
      let rows
      switch (type) {
        case "project":
          apiType = "GoodsCategoryProject";
          that.goodsClassifyTitle = "项目列表";
          rows = that.goodsClassify[index1].Child[index2].Goods.filter(item=>item.GoodsTypeName=='项目')
          break;
        case "product":
          apiType = "GoodsCategoryProduct";
          that.goodsClassifyTitle = "产品列表";
          rows = that.goodsClassify[index1].Child[index2].Goods.filter(item=>item.GoodsTypeName=='产品')
          break;
        case "generalCard":
          apiType = "GoodsCategoryGeneralCard";
          that.goodsClassifyTitle = "通用次卡列表";
          rows = that.goodsClassify[index1].Child[index2].Goods.filter(item=>item.GoodsTypeName=='通用次卡')
          break;
        case "timeCard":
          apiType = "GoodsCategoryTimeCard";
          that.goodsClassifyTitle = "时效卡列表";
          rows = that.goodsClassify[index1].Child[index2].Goods.filter(item=>item.GoodsTypeName=='时效卡')
          break;
        case "saveCard":
          apiType = "GoodsCategorySavingCard";
          that.goodsClassifyTitle = "储值卡列表";
          rows = that.goodsClassify[index1].Child[index2].Goods.filter(item=>item.GoodsTypeName=='储值卡')

          break;
        case "packageCard":
          apiType = "GoodsCategoryPackageCard";
          that.goodsClassifyTitle = "套餐卡列表";
          rows = that.goodsClassify[index1].Child[index2].Goods.filter(item=>item.GoodsTypeName=='套餐卡')
          break;
      }
      this.GoodsCategoryGood(apiType);
      
      if (rows.length) {
        rows.forEach((item) => {
          if (item.GoodsID) {
            item.ID = item.GoodsID;
          }
        });
      }
      that.$nextTick(() => {
        that.$refs.goodsClassify.clearSelection();
        if (rows.length) {
          rows.forEach((row) => {
            that.$refs.goodsClassify.toggleRowSelection(row);
          });
        }
      });
    },
    // 保存选择商品
    saveGoodsClassify() {
      const that = this;
      if (!that.multipleChoice.length) {
        that.$message.error({
          message: "请选择商品",
          duration: 2000,
        });
        return;
      }
      const index1 = that.addGoodsIndex[0];
      const index2 = that.addGoodsIndex[1];
      that.goodsClassify[index1].Child[index2].Goods = [...that.multipleChoice];
      that.goodsClassifyBool = false;
      that.paginations.page = 1;
      that.multipleChoice = [];
      that.$refs.goodsClassify.clearSelection();
    },
    /**  保存网店装修  */
    SaveEShopDecorationConf(){
      let that = this
      switch (that.activeName) {
        case '0'://首页
          that.$refs.HomeDecoration.HomeConfcreate();
          break;
        case '1'://个人中心
          that.PersonalCenterConfCreate()
          break;
        case '2'://商品分类
          that.GoodsCategoryCreate();
          break;
      }
    },


    // 保存分类配置
    GoodsCategoryCreate() {

      // activeName
      const that = this;
      const params = {
        Category: [],
      };
      that.goodsClassify.forEach((item1, index1) => {
        const obj = {
          Name: item1.Name,
          Child: [],
        };
        if (item1.Child.length) {
          item1.Child.forEach((item2, index2) => {
            obj.Child[index2] = {
              Name: item2.Name,
              Goods: [],
            };
            if (item2.Goods.length) {
              item2.Goods.forEach((item3, index3) => {
                obj.Child[index2].Goods[index3] = {
                  GoodsType: item3.GoodsType,
                  GoodsID: item3.GoodsID ? item3.GoodsID : item3.ID,
                };
              });
            }
          });
        }
        params.Category.push(obj);
      });
      API.GoodsCategoryCreate(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "保存成功",
            duration: 2000,
          });
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    /**  保存更新 个人中心配置  */
    PersonalCenterConfCreate(){
      let that = this
      let params = that.centerFormData;
      API.PersonalCenterConfCreate(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success({
            message: "保存成功",
            duration: 2000,
          });
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });

    
    },

    /** c查询客人中心配置   */
    getPersonalCenterConf(){
      let that = this
      API.getPersonalCenterConf().then((res) => {
        if (res.StateCode == 200) {
          that.centerFormData = res.Data
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    
    },


    // 获取分类配置
    GoodsCategoryAll() {
      const that = this;
      API.GoodsCategoryAll().then((res) => {
        if (res.StateCode == 200) {
          if (!res.Data.length) return;
          res.Data.forEach((item) => {
            if (item.Child.length) {
              item.Child.forEach((item) => {
                if (item.Goods.length) {
                  item.Goods.forEach((item) => {
                    item.Name = item.GoodsName;
                  });
                }
              });
            }
          });
          that.goodsClassify = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    formatterTimeCardDay(row) {
      if (row.ConsumeCycle == 0 || row.CycleLimitAmount == 0) {
        return "不限制";
      } else {
        return row.ConsumeCycle;
      }
    },
    formatterTimeCardNum(row) {
      if (row.ConsumeCycle == 0 || row.CycleLimitAmount == 0) {
        return "不限制";
      } else {
        return row.CycleLimitAmount;
      }
    },
    // 点击视图左侧导航
    clickLeftNav(index){
      this.leftNavIndex=index
    },



  },
  /** 监听数据变化   */
  watch: {
    "bannerForm.TypeID": {
      handler() {
        this.bannerForm.name = "";
      },
    },
    "goodsClassify":{
      handler() {
        this.leftNavIndex=0
      }
    }
  },
  /**  实例创建完成之后  */
  created() {
    let that = this;
    that.getPersonalCenterConf();
  },
  /**  实例被挂载后调用  */
  mounted() {
    // // 获取配置详情
    // this.getConfigInfo();
    // // 获取商品类型
    // this.getGoodType();
    // // 线上分类 - 排除没有子类的分类
    // this.getAppletGoodsCategoryValid();
    // 获取分类配置
    this.GoodsCategoryAll();
  },
};
</script>

<style  lang="scss">
.EShopDecoration {
  height: 100%;
  box-sizing: border-box;
  position: relative;
  .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .oneLine {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .twoLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .configBtn {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .el_scrollbar_height {
    height: 70vh;
  }
  .el_scrollbar_height.active {
    height: 50vh;
  }
  .model {
    top: 20px;
    position: relative;
    width: 375px;
    height: 74vh;
    background-color: #fafafa !important;
    margin: 0 auto;
    .top {
      height: 45px;
      line-height: 45px;
      .toggleClick {
        width: 30px;
        height: 18px;
        border-radius: 9px;
        background-color: #eee;
      }
    }
    .search {
      width: 355px;
      height: 35px;
    }
    .bannerLabel {
      height: 35px;
    }
    .title {
      height: 45px;
      line-height: 45px;
    }
    .box {
      width: 355px;
      background: #ffffff;
      border-radius: 5px;
      margin: 10px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      .title {
        height: 45px;
        line-height: 45px;
      }
      .avtiveBtn {
        height: 26px;
        line-height: 26px;
        padding: 0 10px;
        background: linear-gradient(-90deg, #ff5510 0%, #ff8655 100%);
        border-radius: 13px;
        color: #fff;
        font-size: 14px;
      }
    }
    .rmbox {
      width: 172px;
      background: #ffffff;
      border-radius: 5px;
      margin: 10px 0;
      overflow: hidden;
      box-sizing: border-box;
      .labelTag {
        display: inline-block;
        background: linear-gradient(-90deg, #ff5510 0%, #ff8f61 100%);
        color: #fff;
        border-radius: 3px;
        font-size: 11px;
        padding: 0px 3px;
        margin: 2px 5px 0 0;
        box-sizing: border-box;
        line-height: 18px;
      }
      .coupon {
        position: relative;
        display: inline-block;
        background-color: #fff6f3;
        overflow: hidden;
        color: #ff5510;
        box-sizing: border-box;
      }
      .coupon span:nth-child(1) {
        display: inline-block;
        height: 20px;
        line-height: 19px;
        padding: 0 5px;
        border-left: 1px solid rgba(255, 85, 16, 0.4);
        border-top: 1px solid rgba(255, 85, 16, 0.4);
        border-bottom: 1px solid rgba(255, 85, 16, 0.4);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        box-sizing: border-box;
      }
      .coupon span:nth-child(2) {
        display: inline-block;
        height: 20px;
        line-height: 19px;
        padding: 0 5px 0 10px;
        border-right: 1px solid rgba(255, 85, 16, 0.4);
        border-top: 1px solid rgba(255, 85, 16, 0.4);
        border-bottom: 1px solid rgba(255, 85, 16, 0.4);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-sizing: border-box;
      }
      .coupon span:nth-child(3) {
        position: absolute;
        top: -3px;
        left: 18px;
        width: 6px;
        height: 6px;
        border: 1px solid rgba(255, 85, 16, 0.4);
        border-radius: 50%;
        background-color: #fff;
        z-index: 1111;
      }
      .coupon span:nth-child(4) {
        position: absolute;
        bottom: -3px;
        left: 18px;
        width: 6px;
        height: 6px;
        border: 1px solid rgba(255, 85, 16, 0.4);
        border-radius: 50%;
        background-color: #fff;
        z-index: 1111;
      }
    }
    .logo {
      position: absolute;
      right: 30px;
      bottom: -10px;
    }
    .line::before {
      content: "";
      display: inline-block;
      width: 1px;
      height: 15px;
      background-color: #999;
      margin-right: 15px;
    }
    .employee {
      // 设置显示内容的宽
      width: 355px;
      // white-space是防止内容自动折行
      white-space: nowrap;
      // overflow-y设置为hidden，否则会出现上下滚动条
      overflow-y: hidden;
      // 子元素的宽度大于父元素的即可滚动
      overflow-x: scroll;
      // ::-webkit-scrollbar可以设置滚动条样式，display：none，去掉滚动条
      &::-webkit-scrollbar {
        display: none;
      }
      .empItem {
        width: 90px;
        height: 120px;
        margin-right: 10px;
        background-color: #fafafa;
        display: inline-block;
        text-align: center;
        .el-image {
          border-radius: 50%;
          margin: 10px auto;
        }
      }
    }
    .center {
      height: calc(74vh - 249px);
      // position: absolute;
      // top: 150px;
      // left: 50%;
      transform: translateY(-30px);
      border-radius: 15px 15px 0px 0px;
      padding-top: 10px;
      overflow: hidden;
      width: 100%;
      background-color: #fff;
      .couponBox {
        min-height: 80px;
        border-radius: 5px;
      }
      .order {
        height: 80px;
        border-radius: 5px;
        .orderLine {
          width: 11px;
          height: 53px;
          opacity: 1;
          background: linear-gradient(to right, #ffffff 0%, #eeeeee 100%);
        }
      }
      .appointment {
        border-radius: 5px;
        height: 60px;
        .num {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #FF1919;
          font-size: 11px;
          color: #fff;
        }
      }
    }

    .active {
      position: relative;
    }
    .active::before {
      content: "";
      position: absolute;
      left: 0;
      display: block;
      width: 3px;
      height: 100%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      background-color: #ff5510;
    }
    .tag {
      display: inline-block;
      padding: 2px 6px;
      border-radius: 15px !important;
    }
  }
  .el-collapse-item__header:hover {
    .el-icon-error {
      display: block;
    }
  }
  .el-collapse-item__header {
    position: relative;
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
    .el-icon-error {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 22px;
      color: #666;
      display: none;
    }
  }

  .el-collapse-item__header,
  .el-collapse-item__wrap {
    background-color: #f7f8fa !important;
  }
  .classSon {
    .el-card {
      overflow: unset;
    }
    .el-collapse-item__header {
      position: relative;
      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.08);
      .el-icon-error {
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 22px;
        display: none;
      }
    }
    .el-collapse-item__header:hover {
      .el-icon-error {
        display: block;
      }
    }
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      background-color: #fff !important;
    }
    .good {
      position: relative;
      .el-icon-error {
        position: absolute;
        top: -10px;
        right: -10px;
        font-size: 22px;
        color: #666;
        display: none;
      }
    }
    .good:hover {
      .el-icon-error {
        display: block;
      }
    }
  }

  .switchBox {
    height: 40px;
    border-radius: 4px;
    background-color: #f5f7fa;
    border: 1px solid #f2f2f2;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 175px;
    height: 100px;
  }
  .el-upload.el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 115px;
  }
  .el-upload-list__item-thumbnail {
    height: 175px;
    width: 100%;
  }
  .image {
    width: 175px;
    height: 100px;
    overflow: hidden;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 8px 0px 0;
    display: inline-block;
  }
}
</style>
