import AMapLoader from '@amap/amap-jsapi-loader';
 
{/* <script type="text/javascript" src="https://cache.amap.com/lbs/static/addToolbar.js"></script> */}

export default {
  /**  高德地图 配置  */
  GaodeAMapLoaderConf(){
    return new Promise((resolve, reject) =>{
        AMapLoader.load({
          "key": "099636a0fa6f7a6406346197ebcad3d8",    // 申请好的Web端开发者Key，首次调用 load 时必填
          // "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          "plugins": [
            // 'AMap.ElasticMarker', // 灵活点标记，可以随着地图级别改变样式和大小的 Marker
            'AMap.ToolBar', // 工具条，控制地图的缩放、平移等
            'AMap.Scale',  // 比例尺，显示当前地图中心的比例尺
            'AMap.ControlBar', //
            'AMap.Geolocation', //定位，提供了获取用户当前准确位置、所在城市的方法
            'AMap.CitySearch',//城市获取服务，获取用户所在城市信息或根据给定IP参数查询城市信息
            'AMap.Autocomplete', // 输入提示，提供了根据关键字获得提示信息的功能
            'AMap.Geocoder', // 地理编码
            'AMap.PlaceSearch', // 地点搜索服务插件，提供某一特定地区的位置查询服务
          ],         // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          // "AMapUI": {             // 是否加载 AMapUI，缺省不加载
          //     "version": '1.1',   // AMapUI 缺省 1.1
          //     "plugins":[
          //     ],       // 需要加载的 AMapUI ui插件
          // },
          // "Loca":{                // 是否加载 Loca， 缺省不加载
          //   "version": '1.3.2'  // Loca 版本，缺省 1.3.2
          // },
        })
        .then(AMap=>{
          resolve(AMap);
        })
        .catch(err => {
          reject(err)
        })
      })
  },
}


function MapUtil() {
	var map = null;
	var geolocation = null;
	var geocoder = null;
	var inputId = null;
}
 
/**
* 创建或者获取
*/
MapUtil.prototype.m = function (containerId) {
	if (this.map == null) {
		this.map = new AMap.Map(containerId, {
            resizeEnable: true,
            zoom: 17,//地图显示的缩放级别
            keyboardEnable: false
    	});
	}
	return this.map;
}
 
/**
* 绑定输入提示的input标签
*/
MapUtil.prototype.bindSearchInput = function (inputId) {
	if (this.map == null) {
		return false;
	}
	this.inputId = inputId;
	AMap.plugin(['AMap.Autocomplete','AMap.PlaceSearch'],() => {
      var autoOptions = {
        input: inputId//使用联想输入的input的id
      };
      autocomplete= new AMap.Autocomplete(autoOptions);
      var placeSearch = new AMap.PlaceSearch({
            city:'',
            map:this.map
      })
      AMap.event.addListener(autocomplete, "select", (e) => {
         //TODO 针对选中的poi实现自己的功能
         var lat = e.poi.location.lat;
         var lng = e.poi.location.lng;
         this.markPoint(lng, lat);
      });
    });
}
 
/**
* 设置地图标记,并重新指定地图中心
*/
MapUtil.prototype.markPoint = function (lat, lng, isCenter) {
	if (this.map == null) {
		return false;
	}
	//清除之前的Marker
	this.map.clearMap();
	var icon = new AMap.Icon({
        image : './locate-needle.png',//24px*24px
        //icon可缺省，缺省时为默认的蓝色水滴图标，
        imageSize : new AMap.Size(15,30)
	});
	// 设置缩放级别和中心点
	if (isCenter || isCenter === undefined) {
		this.map.setCenter([lat, lng]);
	}
    var marker = new AMap.Marker({
    	map: this.map,
		position: [lat, lng],
		icon: icon
	});
}
 
/**
* 绑定鼠标点击事件，获取点击的经纬度并且Mark
*/
MapUtil.prototype.bindClickEvent = function () {
	if (this.map == null) {
		return false;
	}
	return this.map.on('click', (e) => {
		var lat = e.lnglat.getLat();
		var lng = e.lnglat.getLng();
		this.markPoint(lng, lat, false);
		return this.address(e.lnglat);
	})
}
 
/**
* 定位到当前所在位置,浏览器定位会出现定位不准确问题
*/
MapUtil.prototype.locate = function() {
	if (this.map == null) {
		return false;
	}
	return this.map.plugin('AMap.Geolocation', () => {
		if (this.geolocation == null) {
			this.geolocation = new AMap.Geolocation({
	            enableHighAccuracy: true,//是否使用高精度定位，默认:true
	            timeout: 10000,          //超过10秒后停止定位，默认：无穷大
	            buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
	            zoomToAccuracy: true,      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
	            buttonPosition:'RB'
	        });
		}
        
        this.map.addControl(this.geolocation);
        this.geolocation.getCurrentPosition();
        //返回定位信息
        AMap.event.addListener(this.geolocation, 'complete', (data) => {
        	this.markPoint(data.position.getLng(), data.position.getLat())
        	return this.address(data.position);
        });
        //返回定位出错信息
        AMap.event.addListener(this.geolocation, 'error', function(data) {
        	console.log("定位失败");
        	return false;
        });
    });
}
 
/**
* 根据经纬度获取地址
*/
MapUtil.prototype.address = function(lnglat) {
	if (!lnglat) {
		return false;
	}
	if (this.geocoder == null) {
		AMap.plugin('AMap.Geocoder',() => {
			this.geocoder = new AMap.Geocoder({});
		});
	}
 
	return this.geocoder.getAddress(lnglat, (status, result) => {
		if (status == 'complete') {
			//如果有绑定input标签，那么设置input标签的内容
			var address = result.regeocode.formattedAddress;
			if (this.inputId != null) {
				document.getElementById(this.inputId).value = address;
			}
			return address;
		} else {
			return false;
		}
	})
 
}
