  <template>
  <div class="GoodsList">
     <div v-if="HotGoodsList.length > 0"  class="dis_flex flex_wrap flex_x_between pad_10">
        <div class="commodity marbm_10 bcolor_f radius5 overflow_hidden content_box" v-for="(item,index) in HotGoodsList" :key="index" >
          <el-image :src="item.ImageURL" style="height:170px;width:170px" fit="cover">
          </el-image>
          <div v-if="GoodsConf.some(val=>val=='Name')" class="pad_h_10">
            <div class="martp_10 ">
              <el-tag type="warning" size="mini">{{item.GoodsTypeName}}</el-tag>
              <span class="color_333 font_14 marlt_5">{{item.Name}}</span>
            </div>
            <div class="dis_flex martp_5" style="align-items: flex-end;">
              <div v-if="GoodsConf.some(val=>val=='Price')" class="color_red">¥{{item.Price | NumFormat}}</div>
              <div v-if="GoodsConf.some(val=>val=='OriginalText')" class="marlt_5 font_14 color_999" style="text-decoration:line-through;">{{item.OriginalText}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="dis_flex flex_wrap flex_x_between pad_10">
        <div class="commodity marbm_10 bcolor_f radius5 overflow_hidden content_box" v-for="(item,index) in 2" :key="index" >
          <el-image src="http://pic1.win4000.com/wallpaper/2019-01-23/5c481007f3822.jpg" style="height:170px"></el-image>

          
          <div class="pad_h_10">
            <div class="martp_10 ">
              <el-tag type="warning" size="mini">产品</el-tag>
              <span class="color_333 font_14 marlt_5">此处卡项名称</span>
            </div>
            <div class="dis_flex martp_5 font_14" style="align-items: flex-end;">
              <span class="color_red">¥999.00</span>
              <span class="marlt_5 color_999" style="text-decoration:line-through;">¥1000</span>
            </div>
          </div>
        </div>

      </div>
    
  </div>
</template>

<script>
export default {
 name: 'GoodsList',
  props:{
    HotGoodsList:{
      type:Array,
      default:()=>{
        return [];
      }
    },
    GoodsConf:{
      type:Array,
      default:()=>{
        return ['Name','Price','OriginalText'];
      }
    }
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {

      plImage:[
        "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2019-01-23%2F5c481007f3822.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1626687339&t=d744865171237a51cf0cff36513db1d8",
        "https://image.baidu.com/search/detail?ct=503316480&z=0&tn=baiduimagedetail&ipn=d&word=%E5%9B%BE%E7%89%87%20%E6%B8%85%E6%96%B0&step_word=&ie=utf-8&in=&cl=2&lm=-1&st=-1&hd=0&latest=0&copyright=0&cs=2721557561,234914280&os=1237651790,4049372483&simid=0,0&pn=0&rn=1&di=155870&ln=1029&fr=&fmq=1624095328669_R&ic=0&s=undefined&se=&sme=&tab=0&width=&height=&face=undefined&is=0,0&istype=2&ist=&jit=&bdtype=0&spn=0&pi=0&gsm=0&objurl=https%3A%2F%2Fgimg2.baidu.com%2Fimage_search%2Fsrc%3Dhttp%253A%252F%252Fcdn.duitang.com%252Fuploads%252Fitem%252F201201%252F06%252F20120106101836_FSzuV.thumb.700_0.jpg%26refer%3Dhttp%253A%252F%252Fcdn.duitang.com%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg%3Fsec%3D1626687329%26t%3Dff3fc7df1c195d401c86a4b01c4c7778&rpstart=0&rpnum=0&adpicid=0&nojc=undefined"
      ],

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {
  },
  /** 监听数据变化   */
  watch: {},
  /**  实例创建完成之后  */
  created() {},
  /**  实例被挂载后调用  */
  mounted() {},
}
</script>

<style lang="scss">

.GoodsList{
  .commodity{
    width: 48%;
  }
  .commodity:nth-of-type(odd){
    margin-right: 10px;
  }
  // width: 

 }
</style>
