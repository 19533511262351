<template>
  <div class="RichText ql-container ql-snow">
    <p class=" ql-editor" v-html="richText"></p>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
export default {
 name: 'RichText',
  props:{
    RichTextContent:{
      type:String,
      default:"",
    }
  },
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      richText:""

    }
  },
   /**计算属性  */
  computed: {
  },
  /**  方法集合  */
  methods: {},
  /** 监听数据变化   */
  watch: {
    RichTextContent(val){
      let temp = val;
      const regex = new RegExp("<img", "gi");
      this.richText = temp.replace(regex, `<img style="max-width: 100%;"`);
    }
  },
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {
    let temp = this.RichTextContent;
    const regex = new RegExp("<img", "gi");
    this.richText = temp.replace(regex, `<img style="max-width: 100%;"`);
  },
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {},
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
}
</script>

<style lang="scss">

.RichText.ql-container.ql-snow{
  border: unset !important;  
}
.RichText.ql-container{
  min-height: unset;
  height:auto;
  .ql-editor{
    padding: unset;
  }
}
</style>
