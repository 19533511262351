// 网店装修
import * as API from '@/api/index'
export default {
    /**  配置保存*/
    Create: params => {
        return API.POST('api/homeConf/create', params)
    },
    /**  配置详情*/
    All: params => {
        return API.POST('api/homeConf/all', params)
    },
    /**  秒杀列表*/
    GetSecKill: params => {
        return API.POST('api/SecKill/getSecKill', params)
    },
    /**  拼团列表*/
    GetBookingList: params => {
        return API.POST('api/GroupBooking/getBookingList', params)
    },
    /**  商品列表*/
    GoodAll: params => {
        return API.POST('api/Commodity/all', params)
    },
    /**  商品分类*/
    GoodType: () => {
        return API.POST('api/Commodity/GoodType')
    },
    /**  线上分类 - 排除没有子类的分类*/
    AppletGoodsCategoryValid: params => {
        return API.POST('api/appletGoodsCategory/valid', params)
    },
    /**  上传图片*/
    AddImage: params => {
        return API.POST('api/Commodity/addImage', params)
    },
    // 网店商品分类新增项目商品列表
    GoodsCategoryProject: params => {
        return API.POST('api/appletGoodsCategory/project', params)
    },
    // 网店商品分类新增产品商品列表
    GoodsCategoryProduct: params => {
        return API.POST('api/appletGoodsCategory/product', params)
    },
    // 网店商品分类新增时效卡商品列表
    GoodsCategoryTimeCard: params => {
        return API.POST('api/appletGoodsCategory/timeCard', params)
    },
    // 网店商品分类新增储值卡商品列表
    GoodsCategorySavingCard: params => {
        return API.POST('api/appletGoodsCategory/savingCard', params)
    },
    // 网店商品分类新增通用次卡商品列表
    GoodsCategoryGeneralCard: params => {
        return API.POST('api/appletGoodsCategory/generalCard', params)
    },
    // 网店商品分类新增套餐卡商品列表
    GoodsCategoryPackageCard: params => {
        return API.POST('api/appletGoodsCategory/packageCard', params)
    },
    // 网店商品分类新增
    GoodsCategoryCreate: params => {
        return API.POST('api/appletGoodsCategory/create', params)
    },
    // 网店商品分类配置
    GoodsCategoryAll: params => {
        return API.POST('api/appletGoodsCategory/all', params)
    },
    /**  首页配置组件列表 */
    getComponents: params => {
      return API.POST('api/homeConf/getComponents', params)
    },
    /** 保存首页配置信息  */
    HomeConfcreate: params => {
      return API.POST('api/homeConf/create', params)
    },
    /** 获取首页配置信息  */
    getHomeConf: params => {
      return API.POST('api/homeConf/getHomeConf', params)
    },
    /** 个人中心配置查询  */
    getPersonalCenterConf: params => {
      return API.POST('api/PersonalCenter/all', params)
    },
    /** 个人中心配置保存  */
    PersonalCenterConfCreate: params => {
      return API.POST('api/PersonalCenter/create', params)
    },
}
