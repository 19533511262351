import AMapLoader from '@amap/amap-jsapi-loader';
import {regionData,regionDataPlus,CodeToText,TextToCode,} from "element-china-area-data";

/**
 * 高德地图封装
 *
 * @class Maps
 */
class Maps{

  constructor(option){
    this.option = option;
  }

  createAMapLoader(){
    return new Promise((resolve, reject) =>{
      AMapLoader.load({
        "key": "099636a0fa6f7a6406346197ebcad3d8",    // 申请好的Web端开发者Key，首次调用 load 时必填
        // "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": [
          // 'AMap.ElasticMarker', // 灵活点标记，可以随着地图级别改变样式和大小的 Marker
          'AMap.ToolBar', // 工具条，控制地图的缩放、平移等
          'AMap.Scale',  // 比例尺，显示当前地图中心的比例尺
          'AMap.ControlBar', //
          'AMap.Geolocation', //定位，提供了获取用户当前准确位置、所在城市的方法
          'AMap.CitySearch',//城市获取服务，获取用户所在城市信息或根据给定IP参数查询城市信息
          'AMap.Autocomplete', // 输入提示，提供了根据关键字获得提示信息的功能
          'AMap.Geocoder', // 地理编码
          'AMap.PlaceSearch', // 地点搜索服务插件，提供某一特定地区的位置查询服务
        ],         // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
      .then(AMap=>{
        this.gdmap = new AMap.Map(this.option.dom,this.option.setting);
        resolve(this.gdmap);
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }

  /**
   * 设置工具栏
   *
   * @memberOf Maps
   */
  setGdControl(){
    this.gdmap.setDefaultCursor("pointer");
    // 比例尺
    this.gdmap.addControl(new AMap.Scale({ position: "RB" }));
    // 缩放控件
    this.gdmap.addControl(new AMap.ToolBar({}));
    // 定位信息
    this.gdmap.addControl(new AMap.Geolocation({}));
  }


  /**
   * 重新设置地图中心点
   *
   * @param {any} poisition
   *
   * @memberOf Maps
   */
  changeMapCenter(poisition){
    this.gdmap.setZoomAndCenter(15,poisition)
  }

  /**
   * 在地图上添加mark（定位标记图标）
   *
   * @param {any} markOption
   *
   * @memberOf Maps
   */
  addMark(markOption){
    let self = this
    let icons = ''
    if(markOption.clearMark){
      self.gdmap.clearMap()
    }
    if(markOption.icon){
       icons= new AMap.Icon({
            size: new AMap.Size(40, 50),  //图标大小
            image: markOption.icon,
            imageOffset: new AMap.Pixel(0, -60)
        })
    }
    let mark = new AMap.Marker({
      position:markOption.position,
      icon:icons
    })
    if(markOption.title){
      self.addInfo(markOption);
    }
    mark.setMap(self.gdmap)
    mark.on("click",function () {
      self.addInfo(markOption);
    })
  }
  /**
   * 在地图上添加信息展示窗口
   *
   * @param {any} infoOption
   *
   * @memberOf Maps
   */
  addInfo(infoOption){
    let infoWindow;
     let infos = [];
        infos.push("<div>"+infoOption.title+"</div> ");
        infoWindow = new AMap.InfoWindow({
            content: infos.join("<br/>"),  //使用默认信息窗体框样式，显示信息内容
            offset: new AMap.Pixel(0, -30),
            autoMove:true
        });
        infoWindow.open(this.gdmap, infoOption.position);
  }

  /**  逆向地理编码 返回 详细地址 、省 、市、区  */
  getAddress_geocoder(lng, lat) {
    return new Promise((resolve, reject) =>{
      // let that = this;
      var geocoder = new AMap.Geocoder({
        city: "010", //城市设为北京，默认：“全国”
        radius: 1000, //范围，默认：500
      }); 
  
      geocoder.getAddress([lng, lat], function (status, result) {
        if (status === "complete" && result.regeocode) {
          let province = result.regeocode.addressComponent.province; // 省
          let city = result.regeocode.addressComponent.city; // 市
          let district = result.regeocode.addressComponent.district; // 区
          // let township = result.regeocode.addressComponent.township; // 街道
          let street = result.regeocode.addressComponent.street; // 路
          let streetNumber = result.regeocode.addressComponent.streetNumber; // 号
  
          var address = result.regeocode.formattedAddress.split(district)[1] + street + streetNumber; // 格式化地址
          resolve({address:address,province:province,city:city,district:district});
          // if (that.editOrganization) {
          
          //   that.ruleOrganization.AddressDetail = address;
          // } else {
          //   that.ruleForm.AddressDetail = address;
          // }
          // that.getChinaAreaCode(province, city, district);
        } else {
          console.error("根据经纬度查询地址失败");
          reject(result)
        }
      });

    })
  }

  /**  正向地理编码 传入地址 返回经纬度  */
  getAddress_location(Address) {
    return new Promise((resolve, reject) =>{
      var geocoder = new AMap.Geocoder({
        // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        city: "全国",
      });

      geocoder.getLocation(Address, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          // result中对应详细地理坐标信息
          resolve(result.geocodes[0].location)
        }
        else{
          reject(result)
        }
      });
    });
  }


  /** 地点搜索服务插件，提供某一特定地区的位置查询服务   */
  getPlaceSearch(panel,searchAddress) {
    let currentCity = "全国";  //地点搜索 范围 默认全国
    let placeSearch = new AMap.PlaceSearch({
      //构造地点查询类
      pageSize: 5, // 单页显示结果条数
      pageIndex: 1, // 页码
      city: currentCity, // 兴趣点城市
      // citylimit: true, //是否强制限制在设置的城市内搜索
      map: this.gdmap, // 展现结果的地图实例
      panel: panel, // 结果列表将在此容器中进行展示。
      autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
      type:"汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|道路附属设施|地名地址信息|公共设施"
    });
    
    placeSearch.search(searchAddress, function(status,result){
    });

    return placeSearch;
  
  }
  
  /**  设置城市  */
  setCity(cityName){
    this.gdmap.setCity(cityName);
  }
  

  /**
   *
   * 在地图上标记园
   * @param {any} circleInfo
   *
   * @memberOf Maps
   */
  addCircle(circleInfo){
    let center = new AMap.LngLat(circleInfo.center[0],circleInfo.center[1])
    let Circle = new AMap.Circle({
      center:center,
      radius:circleInfo.radius,
      fillOpacity:circleInfo.Opacity,
      fillColor:circleInfo.bgColor,
      strokeColor:circleInfo.borderColor
    })
    Circle.setMap(this.gdmap)
  }
  /**
   * 按关键字搜索并返回地址列表
   *
   * @param {any} searchInfo 搜索配置信息isGeographicis经纬度，addr地址
   * @returns {arry} info 返回地址数组列表
   *
   * @memberOf Maps
   */
  searchPlace(searchInfo){
    return new Promise(function (resolve, reject) {
      let info = [];
      AMap.service(["AMap.PlaceSearch"], function() {
        let placeSearch = new AMap.PlaceSearch({
          type:"汽车服务|汽车销售|汽车维修|摩托车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|道路附属设施|地名地址信息|公共设施",
          pageSize:searchInfo.size || searchInfo.size===0?searchInfo.size:10
        });
        //判断是否用经纬度搜索
        if(searchInfo.isGeographicis){
          placeSearch.searchNearBy('',searchInfo.Geographicis,searchInfo.range||1000);
        }else{
          placeSearch.search(searchInfo.addr)
        }
        //监听是否搜索完毕
        AMap.event.addListener(placeSearch, "complete", function (result) {
          result.poiList.pois.map(function (value) {
            info.push({
              lng:value.location.lng,
              lat:value.location.lat,
              name:value.name,
              addr:value.address
            });
          });
          resolve(info)
        });
      });
    });
  }

}



export default  Maps;

