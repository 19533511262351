<template>
  <div class="content_body Employee" v-loading="loading">
    <el-container style="height: 100%">
      <el-aside width="280px" style="border-right: 1px solid #ddd">
        <div
          class="dis_flex flex_y_center pad_10"
          style="border-bottom: 1px solid #e4e6e9"
        >
          <el-input
            class="marrt_10"
            size="small"
            placeholder="搜索部门、员工"
            prefix-icon="el-icon-search"
            v-model.trim="tissueSearchKey"
            clearable
            @keyup.enter.native="contactQuery"
            @clear="contactQuery"
          ></el-input>
          <i @click="addEntityModal" class="el-icon-plus"></i>
        </div>

        <el-tree
          class="padtp_10"
          :expand-on-click-node="false"
          node-key="ID"
          :data="entityList"
          :auto-expand-parent="true"
          :props="defaultProps"
          @node-click="handleNodeClick"
          :default-expanded-keys="entityDefaultExpandedKeys"
          draggable
          :allow-drag="allowDrag"
          :allow-drop="allowDrop"
          @node-drop="handleDrop"
        >
          <span slot-scope="{ data }">
            <span class="font_14">{{ data.EntityName }}</span>
            <el-tag class="marlt_5" type="info" size="mini" v-if="data.IsStore"
              >门店</el-tag
            >
            <el-tag
              class="marlt_5"
              type="info"
              size="mini"
              v-if="data.IsWarehouse"
              >仓库</el-tag
            >
          </span>
        </el-tree>
      </el-aside>
      <el-container>
        <el-header style="height: auto; padding: 0px">
          <div class="nav_header">
            <el-row>
              <el-col :span="22">
                <el-form inline size="small" label-width="80px">
                  <el-form-item>
                    <!-- class="pad_10 dis_flex flex_x_between flex_y_center" -->
                    <div class="padlt_10">
                      <el-dropdown
                        @command="handleCommand"
                        v-show="departmentTitle"
                      >
                        <span class="el-dropdown-link">
                          {{ departmentTitle }}({{ paginations.total }}人)
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="add"
                            >添加子部门</el-dropdown-item
                          >
                          <el-dropdown-item
                            command="edit"
                            v-if="departmentParentID != 0"
                            >修改</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </el-form-item>

                  <el-form-item label="职务" prop="JobID">
                    <el-select
                      v-model="JobID"
                      placeholder="请选择"
                      size="small"
                      filterable
                      clearable
                      @change="searchEmployeeData"
                    >
                      <el-option
                        v-for="item in jobTypeData"
                        :label="item.JobName"
                        :value="item.ID"
                        :key="item.ID"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="在职状态">
                    <el-select
                      v-model="State"
                      placeholder="请选择会员等级"
                      clearable
                      @change="searchEmployeeData"
                    >
                      <el-option
                        v-for="item in [
                          { state: '在职', code: true },
                          { state: '离职', code: false },
                        ]"
                        :key="item.state"
                        :label="item.state"
                        :value="item.code"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      v-if="employeeExcel"
                      type="primary"
                      size="small"
                      :loading="downloadLoading"
                      @click="downloadExcel"
                      >导出</el-button
                    >
                  </el-form-item>
                </el-form>
              </el-col>
              <el-col :span="2" class="text_right">
                <el-button
                  @click="addEmployeeModal"
                  type="primary"
                  size="small"
                  v-prevent-click
                  >新增员工</el-button
                >
              </el-col>
            </el-row>
          </div>
        </el-header>
        <el-main style="padding: 0px">
          <div>
            <el-table
              size="small"
              ref="multipleTable"
              :data="tableData"
              tooltip-effect="light"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="ID" label="员工编号"></el-table-column>
              <el-table-column prop="Name" label="员工姓名"></el-table-column>
              <el-table-column
                label="所属单位"
                show-overflow-tooltip
                min-width="140px"
              >
                <template slot-scope="scope">
                  <span v-for="(item, index) in scope.row.Entity" :key="index"
                    >{{ item.EntityName }}{{ item.IsPrimaryEntity ? "[主]" : ""
                    }}{{
                      scope.row.Entity.length == index + 1 ? "" : ","
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="JobName" label="职务"></el-table-column>
              <el-table-column
                prop="State"
                label="在职状态"
                :formatter="formatStatus"
              ></el-table-column>
              <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="toStaffDetails(scope.row)"
                    v-prevent-click
                    >编辑</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            class="pad_10 dis_flex flex_x_between"
            v-if="paginations.total > 0"
          >
            <el-dropdown size="small" @command="handleSelect">
              <el-button type="primary" size="small" v-prevent-click>
                批量设置
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu trigger="click" slot="dropdown">
                <el-dropdown-item command="duty">职务</el-dropdown-item>
                <el-dropdown-item command="belongToUnit"
                  >所属单位</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page.sync="paginations.page"
              :page-size="paginations.page_size"
              :page-sizes="[10, 15, 20, 30, 40, 50]"
              :layout="paginations.layout"
              :total="paginations.total"
            ></el-pagination>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <!--新增员工-->
    <el-dialog
      title="新增员工"
      :visible.sync="addstaff"
      width="950px"
      @closed="resetForm('ruleForms')"
    >
      <el-form
        :rules="rules"
        :model="ruleForms"
        ref="ruleForms"
        label-width="120px"
        size="small"
      >
        <div class="tip">基础档案</div>
        <el-form-item label="员工姓名" prop="name">
          <el-input v-model="ruleForms.name"></el-input>
        </el-form-item>
        <el-form-item label="员工花名" prop="Alias">
          <el-input v-model="ruleForms.Alias"></el-input>
        </el-form-item>
        <el-form-item label="员工编号" prop="empId">
          <div>
            <el-input v-model="ruleForms.empId"></el-input>
            <span class="color_gray padlt_20 font_12" style="height: 18px"
              >员工唯一标识，设定后不允许修改，可以使用工号、邮箱等公司系统内统一的ID</span
            >
          </div>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input maxlength="11" v-model="ruleForms.phone"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="Gender">
          <el-radio-group v-model="ruleForms.Gender">
            <el-radio label="2">女</el-radio>
            <el-radio label="1">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="tip">聘用信息</div>
        <el-form-item label="职务" prop="duty">
          <el-select v-model="ruleForms.duty" placeholder="请选择" filterable>
            <el-option
              v-for="item in jobTypeData"
              :label="item.JobName"
              :value="item.ID"
              :key="item.ID"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属单位" prop="Entity">
          <el-tag
            :class="index == 0 ? '' : 'marlt_5'"
            v-for="(tag, index) in ruleForms.Entity"
            :key="tag.EntityID"
            closable
            @close="handleClose(tag)"
            >{{ tag.EntityName }}{{ tag.IsPrimaryEntity ? "[主]" : "" }}</el-tag
          >
          <el-link
            class="marlt_5"
            type="primary"
            :underline="false"
            @click="addModifyEntity"
            >修改</el-link
          >
          <p v-if="showTip" class="font_12 color_red">
            友情提醒：员工一般要配置到门店或仓库等具体部门才能开展业务 ！
          </p>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="resetForm('ruleForms')" v-prevent-click
          >取消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="submitAddEmployee('ruleForms')"
          :loading="modalLoading"
          v-prevent-click
          >保存</el-button
        >
      </div>
    </el-dialog>
    <!--新增员工所属单位弹窗-->
    <el-dialog
      title="选择员工所在部门"
      width="950px"
      :visible.sync="addBelongToDia"
      @close="clickAddEmpBelongToCancalBtn"
    >
      <el-row :gutter="20">
        <el-col :span="12" style="border-right: 1px dotted #dce1e6">
          <div class="back_f7 min_hei_300 pad_10_15">
            <el-input
              v-model="departmentName"
              class="marbm_15"
              size="small"
              placeholder="请输入名称"
              clearable
            ></el-input>
            <el-scrollbar class="el_scrollbar_height_dept">
              <el-tree
                class="filter-tree"
                ref="tree"
                :expand-on-click-node="false"
                :data="entityTreeList"
                show-checkbox
                node-key="ID"
                :default-expanded-keys="[1]"
                :default-checked-keys="treeDefaultCheckedKeys"
                :props="defaultProps"
                @check="getCheckedData"
                :check-strictly="true"
                :filter-node-method="filterNode"
                :check-on-click-node="true"
              >
                <span slot-scope="{ data }">
                  <span>{{ data.EntityName }}</span>
                  <el-tag
                    class="marlt_5"
                    type="info"
                    size="mini"
                    v-if="data.IsStore"
                    >门店</el-tag
                  >
                  <el-tag
                    class="marlt_5"
                    type="info"
                    size="mini"
                    v-if="data.IsWarehouse"
                    >仓库</el-tag
                  >
                </span>
              </el-tree>
            </el-scrollbar>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="back_f7 min_hei_300 pad_10_15">
            <div>已选择的部门</div>
            <el-scrollbar class="el_scrollbar_height_dept">
              <div
                class="martp_15 dis_flex flex_x_between flex_y_center"
                style="width:98%"
                v-for="(item, index) in checkedData"
                :key="index"
                @mouseenter="dataDetails(item, index)"
                @mouseleave="hiddenDetail(index)"
              >
                <div class="dis_flex font_14">
                  <div>{{ item.EntityName }}</div>
                  <el-tag
                    class="marlt_5"
                    type="info"
                    size="mini"
                    v-if="item.IsStore"
                    >门店</el-tag
                  >
                  <el-tag
                    class="marlt_5"
                    type="info"
                    size="mini"
                    v-if="item.IsWarehouse"
                    >仓库</el-tag
                  >
                </div>
                <div class="dis_flex flex_y_center flex_x_end">
                  <div
                    class="marrt_10 font_12 color_gray"
                    v-if="item.IsPrimaryEntity"
                  >
                    主部门
                  </div>
                  <div
                    class="marrt_10 font_12 color_gray"
                    style="cursor: pointer"
                    v-if="item.isMouseenter && !item.IsPrimaryEntity"
                    @click="setSection(index)"
                  >
                    设为主部门
                  </div>
                  <i
                    class="el-icon-close"
                    style="cursor: pointer"
                    @click="removeSection(index, item)"
                  ></i>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="clickAddEmpBelongToCancalBtn"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="clickAddEmpBelongToBtn"
          v-prevent-click
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--员工编辑弹出框-->
    <el-dialog title="员工详情" :visible.sync="empEditState" width="60%">
      <el-form
        :model="formInline"
        :rules="rules_formInline"
        ref="formInline"
        label-width="120px"
        size="small"
        class="demo-ruleForm"
      >
        <div class="tip">基础档案</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="员工编号" prop="ID">
              <el-input
                v-model="formInline.ID"
                size="small"
                disabled
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="员工姓名" prop="Name">
              <el-input
                v-model="formInline.Name"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="员工花名">
              <el-input
                v-model="formInline.Alias"
                placeholder="请输入员工花名"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="手机号" prop="PhoneNumber">
              <el-input
                v-model="formInline.PhoneNumber"
                maxlength="11"
                size="small"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别" prop="Gender">
              <el-radio-group v-model="formInline.Gender">
                <el-radio label="2">女</el-radio>
                <el-radio label="1">男</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formInline.Birthdate"
                style="width: 100%"
                size="small"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="证件号码">
              <el-input
                v-model="formInline.IDNumber"
                placeholder="请输入证件号码"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="16">
            <el-form-item label="地址">
              <el-input
                v-model="formInline.Address"
                size="small"
                placeholder="请输入地址信息"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="tip">聘用信息</div>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="职务" prop="JobID">
              <el-select
                v-model="formInline.JobID"
                placeholder="请选择"
                size="small"
                filterable
              >
                <el-option
                  v-for="item in jobTypeData"
                  :label="item.JobName"
                  :value="item.ID"
                  :key="item.ID"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入职日期">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formInline.EntryDate"
                style="width: 100%"
                size="small"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="试用期到期日">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formInline.TrialExpiryDate"
                style="width: 100%"
                size="small"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="在职状态" prop="State">
              <el-radio-group v-model="formInline.State">
                <el-radio :label="true">在职</el-radio>
                <el-radio :label="false">离职</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合同日期">
              <el-date-picker
                unlink-panels
                size="small"
                v-model="timeData"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="社保缴纳日期">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="formInline.SocialSecurityPaymentDate"
                style="width: 100%"
                size="small"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="所属单位" prop="Entity">
              <el-tag
                :class="index == 0 ? '' : 'marlt_5'"
                v-for="(tag, index) in formInline.Entity"
                :key="tag.EntityID"
                closable
                @close="handleCloseEdit(tag)"
                >{{ tag.EntityName
                }}{{ tag.IsPrimaryEntity ? "[主]" : "" }}</el-tag
              >
              <el-link
                class="marlt_5"
                type="primary"
                :underline="false"
                @click="editModifyEntity"
                >修改</el-link
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="备注" prop="Content">
              <el-input
                type="textarea"
                :rows="4"
                v-model="formInline.Remark"
                placeholder="请填写备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="empEditState = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="clickSaveBtn"
          :loading="modalLoading"
          v-prevent-click
          >保 存</el-button
        >
      </div>
    </el-dialog>
    <!--新增组织-->
    <el-dialog
      title="新增组织"
      custom-class="addorganizationClass"
      :visible.sync="addorganization"
      width="1000px"
      @close="addorganizationClose"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="0">
          <el-scrollbar class="el_scrollbar_height">
            <el-form
              :rules="rules_2"
              size="small"
              :model="ruleForm"
              ref="ruleForm"
              label-width="120px"
            >
              <el-form-item label="上级单位" prop="ParentID">
                <treeselect
                  v-model="ruleForm.ParentID"
                  :options="entityTreeList"
                  :normalizer="normalizer"
                  clearValueText
                  noResultsText="无匹配数据"
                  placeholder="选择上级部门"
                />
              </el-form-item>
              <el-form-item label="单位名称" prop="EntityName">
                <el-input
                  style="width: 200px"
                  v-model="ruleForm.EntityName"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否门店" prop="IsStore">
                <el-radio-group
                  v-model="ruleForm.IsStore"
                  @change="IsStoreChange"
                >
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="是否仓库" prop="IsWarehouse">
                <!-- @change="IsWarehouseChange" -->
                <el-radio-group
                  v-model="ruleForm.IsWarehouse"
                  :disabled="ruleForm.IsStore"
                >
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <!-- <span class="padlt_20 marlt_20 color_gray">当前单位既是仓库也是单位（门店或非门店）</span> -->
              </el-form-item>
              <el-form-item label="加盟店" prop="IsJoin">
                <el-radio-group v-model="ruleForm.IsJoin">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="地址" prop="selectedOptions">
                <el-cascader
                  clearable
                  placeholder="请选择省 / 市 / 区"
                  size="small"
                  :options="options"
                  v-model="ruleForm.selectedOptions"
                  @change="handleChange"
                >
                </el-cascader>
              </el-form-item>
              <el-form-item label="详细地址" prop="AddressDetail">
                <el-input
                  v-model="ruleForm.AddressDetail"
                  placeholder="请输入具体地址"
                  class="marrt_10"
                ></el-input>
                <el-button type="primary" plain @click="getPlaceSearch"
                  >搜索地图</el-button
                >
              </el-form-item>

              <el-form-item label="地图定位" prop="Latitude">
                <div class="position_relative">
                  <!-- 地图容器 -->
                  <div id="container"></div>
                  <!-- 展示搜索结果的容器 -->
                  <div id="panel"></div>
                </div>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane v-if="ruleForm.IsStore" label="门店信息" name="1">
          <el-scrollbar class="el_scrollbar_height">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm1"
              label-width="100px"
              class="demo-ruleForm"
              size="small"
            >
              <el-form-item label="客服电话" prop="ServiceTelephoneNumber">
                <el-input
                  maxlength="11"
                  v-model="ruleForm.ServiceTelephoneNumber"
                ></el-input>
              </el-form-item>

              <el-form-item label="门店logo">
                <div class="color_999">只上传1张，建议尺寸宽：高为1:1</div>

                <el-upload
                  action="#"
                  :limit="1"
                  list-type="picture-card"
                  :file-list="ruleForm.Logo_file"
                  :before-upload="beforeAvatarUpload"
                >
                  <i
                    slot="default"
                    class="el-icon-camera-solid"
                    style="font-size: 40px; color: #999"
                  ></i>

                  <div
                    slot="file"
                    slot-scope="{ file }"
                    style="height:100px;width:100px"
                  >
                    <el-image
                      class="el-upload-list__item-thumbnail"
                      :id="file.uid"
                      :src="file.url"
                      :preview-src-list="showFileList_1"
                      :z-index="9999"
                      fit="cover"
                    ></el-image>
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="DialogPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>

                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file, 10)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item label="门店照片">
                <div class="color_999">最多上传5张，建议尺寸宽：高为16:9</div>
                <el-upload
                  action="#"
                  :limit="5"
                  list-type="picture-card"
                  :file-list="ruleForm.Photo_file_list"
                  :before-upload="
                    ($event) => storePhotoBeforeAvatarUpload($event, false)
                  "
                >
                  <i
                    slot="default"
                    class="el-icon-camera-solid"
                    style="font-size: 40px; color: #999"
                  ></i>
                  <div
                    slot="file"
                    slot-scope="{ file }"
                    style="height:100px;width:100px"
                  >
                    <el-image
                      class="el-upload-list__item-thumbnail"
                      :id="file.uid"
                      :src="file.url"
                      :preview-src-list="showFileList_2"
                      :z-index="9999"
                      fit="cover"
                    ></el-image>
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="DialogPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>

                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file, 20)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
              <el-form-item label="营业时间">
                <el-button
                  @click="selBusinessHours(index)"
                  v-for="(item, index) in businessHours"
                  :key="index"
                  :type="item.bool ? 'primary' : ''"
                  >{{ item.name }}</el-button
                >
                <div class="martp_20">
                  <el-time-picker
                    is-range
                    arrow-control
                    v-model="ruleForm.BusinessTime"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                  >
                  </el-time-picker>
                </div>
              </el-form-item>
              <el-form-item label="店铺简介" prop="intro">
                <el-input
                  style="width: 95%"
                  type="textarea"
                  v-model="ruleForm.Description"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane name="2" label="可采购门店/仓库">
          <el-scrollbar class="el_scrollbar_height">
            <el-tree
              ref="refPurchase"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              :check-strictly="true"
              :data="storeList"
              show-checkbox
              node-key="ID"
              :default-checked-keys="defaultCheckedPurchase"
              :props="defaultProps"
            ></el-tree>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane name="3" label="可调拨门店/仓库">
          <el-scrollbar class="el_scrollbar_height">
            <el-tree
              ref="refSale"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              :check-strictly="true"
              :data="storeList"
              show-checkbox
              node-key="ID"
              :default-checked-keys="defaultCheckedKeysSale"
              :props="defaultProps"
            ></el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="addorganization = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="addEntity"
          :loading="modalLoading"
          v-prevent-click
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!--编辑组织-->
    <el-dialog
      title="编辑组织"
      custom-class="addorganizationClass"
      :visible.sync="editOrganization"
      width="1000px"
      @closed="closedEditDialog"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="基础信息" name="0">
          <el-scrollbar class="el_scrollbar_height">
            <el-form
              :rules="rules_3"
              size="small"
              :model="ruleOrganization"
              ref="ruleOrganization"
              label-width="120px"
            >
              <el-form-item label="上级单位" prop="ParentID">
                <treeselect
                  v-model="ruleOrganization.ParentID"
                  :options="entityTreeList"
                  :normalizer="normalizer"
                  clearValueText
                  noResultsText="无匹配数据"
                  placeholder="选择上级部门"
                />
              </el-form-item>
              <el-form-item label="单位名称" prop="EntityName">
                <el-input
                  style="width: 200px"
                  v-model="ruleOrganization.EntityName"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否门店" prop="IsStore">
                <el-radio-group
                  v-model="ruleOrganization.IsStore"
                  @change="IsEditStoreChange"
                  :disabled="true"
                >
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <span class="padlt_20 marlt_20 color_gray"
                  >当前单位可为部门、区域、市场等</span
                >
              </el-form-item>
              <el-form-item label="是否仓库" prop="IsWarehouse">
                <el-radio-group
                  v-model="ruleOrganization.IsWarehouse"
                  :disabled="true"
                >
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
                <span class="padlt_20 marlt_20 color_gray"
                  >当前单位既是仓库也是单位（门店或非门店）</span
                >
              </el-form-item>
              <el-form-item label="是否有效" prop="Active">
                <el-radio-group v-model="ruleOrganization.Active">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="加盟店">
                <el-radio-group v-model="ruleOrganization.IsJoin">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="地址" prop="selectedOptions">
                <el-cascader
                  clearable
                  placeholder="请选择省 / 市 / 区"
                  size="small"
                  :options="options"
                  v-model="ruleOrganization.selectedOptions"
                  @change="handleChange"
                >
                </el-cascader>
              </el-form-item>
              <el-form-item label="详细地址" prop="AddressDetail">
                <el-input
                  v-model="ruleOrganization.AddressDetail"
                  placeholder="请输入具体地址"
                  class="marrt_10"
                  @keyup.enter.native="getPlaceSearch"
                ></el-input>
                <el-button type="primary" plain @click="getPlaceSearch"
                  >搜索地图</el-button
                >
              </el-form-item>

              <el-form-item label="地图定位" prop="Latitude">
                <div class="position_relative">
                  <!-- 地图容器 -->
                  <div id="editContainer"></div>
                  <!-- 展示搜索结果的容器 -->
                  <div id="editPanel"></div>
                </div>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-tab-pane>

        <el-tab-pane v-if="ruleOrganization.IsStore" label="门店信息" name="1">
          <el-scrollbar class="el_scrollbar_height">
            <el-form
              :model="ruleOrganization"
              :rules="rules_3"
              ref="ruleOrganization1"
              label-width="100px"
              size="small"
            >
              <el-form-item label="客服电话" prop="ServiceTelephoneNumber">
                <el-input
                  maxlength="11"
                  v-model="ruleOrganization.ServiceTelephoneNumber"
                ></el-input>
              </el-form-item>

              <el-form-item label="门店logo">
                <div class="color_999">只上传1张，建议尺寸宽：高为1:1</div>

                <el-upload
                  action="#"
                  :limit="1"
                  list-type="picture-card"
                  :file-list="ruleOrganization.Logo_file"
                  :before-upload="($event) => beforeAvatarUpload($event, true)"
                >
                  <i
                    slot="default"
                    class="el-icon-camera-solid"
                    style="font-size: 40px; color: #999"
                  ></i>
                  <div
                    slot="file"
                    slot-scope="{ file }"
                    style="height:100px;width:100px"
                  >
                    <el-image
                      class="el-upload-list__item-thumbnail"
                      :id="file.uid"
                      :src="file.url"
                      :preview-src-list="showFileList_3"
                      :z-index="9999"
                      fit="cover"
                    ></el-image>
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="DialogPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>

                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file, 30)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item label="门店照片">
                <div class="color_999">最多上传5张，建议尺寸宽：高为16:9</div>
                <el-upload
                  action="#"
                  :limit="5"
                  list-type="picture-card"
                  :file-list="ruleOrganization.Photo_file_list"
                  :before-upload="
                    ($event) => storePhotoBeforeAvatarUpload($event, true)
                  "
                >
                  <i
                    slot="default"
                    class="el-icon-camera-solid"
                    style="font-size: 40px; color: #999"
                  ></i>
                  <!-- :on-remove="(file,fileList)=>handleRemove(file,fileList,40)" -->

                  <div
                    slot="file"
                    slot-scope="{ file }"
                    style="height:100px;width:100px"
                  >
                    <el-image
                      class="el-upload-list__item-thumbnail"
                      :id="file.uid"
                      :src="file.url"
                      :preview-src-list="showFileList_4"
                      :z-index="9999"
                      fit="cover"
                    ></el-image>
                    <span class="el-upload-list__item-actions">
                      <span
                        class="el-upload-list__item-preview"
                        @click="DialogPreview(file)"
                      >
                        <i class="el-icon-zoom-in"></i>
                      </span>

                      <span
                        class="el-upload-list__item-delete"
                        @click="handleRemove(file, 40)"
                      >
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                  <!-- <div>
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-delete">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div> -->
                </el-upload>
              </el-form-item>
              <el-form-item label="营业时间">
                <el-button
                  @click="selBusinessHours(index)"
                  v-for="(item, index) in businessHours"
                  :key="index"
                  :type="item.bool ? 'primary' : ''"
                  >{{ item.name }}</el-button
                >
                <div class="martp_20">
                  <el-time-picker
                    is-range
                    arrow-control
                    v-model="ruleOrganization.BusinessTime"
                    range-separator="至"
                    start-placeholder="开始时间"
                    end-placeholder="结束时间"
                    placeholder="选择时间范围"
                    value-format="HH:mm:ss"
                  >
                  </el-time-picker>
                </div>
              </el-form-item>
              <el-form-item label="店铺简介" prop="intro">
                <el-input
                  :rows="4"
                  type="textarea"
                  v-model="ruleOrganization.Description"
                ></el-input>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane name="2" label="可采购门店/仓库">
          <el-scrollbar class="el_scrollbar_height">
            <el-tree
              ref="refEditPur"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              :check-strictly="true"
              :data="storeList"
              show-checkbox
              node-key="ID"
              :default-checked-keys="defaultCheckedPurchase"
              :props="defaultProps"
            ></el-tree>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane name="3" label="可调拨门店/仓库">
          <el-scrollbar class="el_scrollbar_height">
            <el-tree
              ref="refEditSale"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              :check-strictly="true"
              :data="storeList"
              show-checkbox
              node-key="ID"
              :default-checked-keys="defaultCheckedKeysSale"
              :props="defaultProps"
            ></el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          @click="editOrganization = false"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="submitoOrganization"
          :loading="modalLoading"
          v-prevent-click
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!--批量设置职务弹出框-->
    <el-dialog :visible.sync="dialogVisible" width="30%">
      <template slot="title">
        <span class="font_18 font_weight_300 marrt_15">批量设置职务</span>
        <span class="color_gray">已选中{{ employeeNum }}个员工</span>
      </template>
      <el-form
        :rules="rules_duty"
        size="small"
        :model="dutyRuleForms"
        ref="dutyRuleForms"
        label-width="120px"
        class="demo-ruleForm padtp_20"
      >
        <el-form-item label="职务" prop="duty">
          <el-select
            v-model="dutyRuleForms.duty"
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in jobTypeData"
              :label="item.JobName"
              :value="item.ID"
              :key="item.ID"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="clickSureBtn"
          size="small"
          :loading="modalLoading"
          v-prevent-click
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--批量设置所属单位弹窗-->
    <el-dialog :visible.sync="belongto">
      <template slot="title">
        <span class="font_18 font_weight_300 marrt_15">批量设置所属单位</span>
        <span class="color_gray">已选中{{ employeeNum }}个员工</span>
      </template>
      <el-row :gutter="20">
        <el-col :span="12" style="border-right: 1px dotted #dce1e6">
          <div class="back_f7 min_hei_300 pad_10_15">
            <el-input
              v-model="departmentName"
              class="marbm_15"
              size="small"
              placeholder="请输入名称"
              clearable
            ></el-input>
            <el-tree
              class="filter-tree"
              ref="tree"
              :expand-on-click-node="false"
              :data="entityTreeList"
              show-checkbox
              node-key="ID"
              :default-expanded-keys="[1]"
              :props="defaultProps"
              @check="getCheckedData"
              :check-strictly="true"
              :filter-node-method="filterNode"
              :check-on-click-node="true"
            >
              <span slot-scope="{ data }">
                <span>{{ data.EntityName }}</span>
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsStore"
                  >门店</el-tag
                >
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsWarehouse"
                  >仓库</el-tag
                >
              </span>
            </el-tree>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="back_f7 min_hei_300 pad_10_15">
            <div>已选择的部门</div>
            <div
              class="martp_15 dis_flex flex_x_between flex_y_center"
              v-for="(item, index) in checkedData"
              :key="index"
              @mouseenter="dataDetails(item, index)"
              @mouseleave="hiddenDetail(index)"
            >
              <div class="dis_flex font_14">
                <div>{{ item.EntityName }}</div>
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="item.IsStore"
                  >门店</el-tag
                >
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="item.IsWarehouse"
                  >仓库</el-tag
                >
              </div>
              <div class="dis_flex flex_y_center flex_x_end">
                <div
                  class="marrt_10 font_12 color_gray"
                  v-if="item.IsPrimaryEntity"
                >
                  主部门
                </div>
                <div
                  class="marrt_10 font_12 color_gray"
                  style="cursor: pointer"
                  v-if="item.isMouseenter && !item.IsPrimaryEntity"
                  @click="setSection(index)"
                >
                  设为主部门
                </div>
                <i
                  class="el-icon-close r"
                  @click="removeSection(index, item)"
                ></i>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="belongto = false" v-prevent-click
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="clickBelongToSureBtn"
          v-prevent-click
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import APIEntity from "@/api/KHS/Entity/entity";
import APIEmployee from "@/api/KHS/Entity/employee";
import APIJob from "@/api/KHS/Entity/jobtype";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import validate from "@/components/js/validate.js";
import {
  regionData,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import AMapLoader from "@/api/gaodeAMapLoader";
import gdMaps from "@/api/gdMap";
// import AMapLoader from '@amap/amap-jsapi-loader';
import permission from "@/components/js/permission.js";
import date from "@/components/js/date.js";
import utils from "@/components/js/utils.js";

var Enumerable = require("linq");
var placeSearch;

export default {
  name: "Employee",
  components: { Treeselect },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.employeeExcel = permission.permission(
        to.meta.Permission,
        "KHS-Entity-Employee-Export"
      );
    });
  },

  data() {
    return {
      storeEntityId: "",
      defaultProps: {
        children: "Child",
        label: "EntityName",
      }, // 活动范围选择配置项
      defaultCheckedPurchase: [], //采购默认选中门店
      defaultCheckedKeysSale: [],
      downloadLoading: false,
      employeeExcel: false,
      showTip: false,
      loading: false,
      modalLoading: false,
      isTree: false,
      tableData: [],
      entityList: [], //组织架构
      storeList: [], //可采购/调拨 门店
      entityTreeList: [],
      entityDefaultExpandedKeys: [1],
      entityId: "",
      tissueSearchKey: "", //组织架构搜索
      searchKey: "",
      employeeNum: 0, // 选中员工的个数
      dialogVisible: false, //批量设置职务
      departmentTitle: "", // 选中部门
      departmentParentID: "", // 选中部门
      jobTypeData: [], // 员工职务数据
      JobTypeName: "", // 员工职务参数
      isEmployee: 1, //员工 1 新增 2 编辑
      addstaff: false, //新增员工
      addBelongToDia: false, //新增员工部门弹窗
      departmentName: "", //部门弹窗搜索
      checkedData: [], //已选择的部门 （弹窗）
      treeDefaultCheckedKeys: [],
      addorganization: false, //新增组织
      empEditState: false, // 员工编辑页面
      belongto: false, //所属单位弹窗，
      editOrganization: false, //编辑组织弹窗
      multipleSelection: [], //右边表格选择的
      EmployeeID: [], // 批量设置员工ID
      timeData: "",
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
      //需要给分页组件传的信息
      paginations: {
        page: 1, // 当前位于哪页
        total: 1, // 总数
        page_size: 15, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },

      //新增员工
      ruleForms: {
        name: "",
        Alias: "",
        empId: "",
        phone: "",
        Gender: "2",
        duty: "",
        unit: "",
        Entity: [],
      },
      rules: {
        name: [{ required: true, message: "请输入员工姓名", trigger: "blur" }],
        empId: [{ required: true, message: "请输入员工编号", trigger: "blur" }],
        phone: [
          {
            required: true,
            validator: validate.validPhoneNumber,
            trigger: "blur",
          },
        ],
        Gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
        duty: [{ required: true, message: "请选择职务", trigger: "change" }],
        Entity: [
          { required: true, message: "请选择所属单位", trigger: "change" },
        ],
      },
      activeName: "0",
      businessHours: [
        {
          name: "周一",
          id: 0,
          bool: false,
        },
        {
          name: "周二",
          id: 1,
          bool: false,
        },
        {
          name: "周三",
          id: 2,
          bool: false,
        },
        {
          name: "周四",
          id: 3,
          bool: false,
        },
        {
          name: "周五",
          id: 4,
          bool: false,
        },
        {
          name: "周六",
          id: 5,
          bool: false,
        },
        {
          name: "周日",
          id: 6,
          bool: false,
        },
      ],
      //新增组织
      ruleForm: {
        IsJoin: true, // 是否为加盟店
        ParentID: null,
        EntityName: "",
        IsStore: true,
        IsWarehouse: false,
        IsOpenOnlineShop: false,
        selectedOptions: "",
        ProvinceCode: "", //省、
        CityCode: "", //市、
        AreaCode: "", //区、
        AddressDetail: "", //详细地址、
        Longitude: "", //经度、
        Latitude: "", //纬度
        EntityLogoURL: "", //门店logo
        Logo_file: [], //门店logo
        ServiceTelephoneNumber: "", // 服务电话
        BusinessTime: "",
        BusinessStartTime: "", // 服务开始时间
        BusinessEndTime: "", // 服务结束时间
        EntityBusinessDate: [], // 营业事假
        Description: "", // 门店简介
        PhotoURLList: [], //  门店照片
        Photo_file_list: [], //  门店照片
      },
      rules_2: {
        ParentID: [
          { required: true, message: "请选择上级单位", trigger: "change" },
        ],
        IsJoin: [
          { required: true, message: "选择是否加盟店", trigger: "blur" },
        ],
        EntityName: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        IsStore: [
          { required: true, message: "请选择是否为门店", trigger: "change" },
        ],
        IsWarehouse: [
          { required: true, message: "请选择是否为仓库", trigger: "change" },
        ],
        selectedOptions: [
          { required: false, message: "请选择地址", trigger: "change" },
        ],
        AddressDetail: [
          { required: false, message: "请输入详细地址", trigger: "change" },
        ],
        Latitude: [
          {
            require: false,
            message: "请选择详细地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      //编辑组织
      ruleOrganization: {
        ID: "",
        ParentID: "",
        EntityName: "",
        IsStore: false,
        IsJoin: false,
        IsWarehouse: false,
        Active: false,
        selectedOptions: "",
        ProvinceCode: "", //省、
        CityCode: "", //市、
        AreaCode: "", //区、
        AddressDetail: "", //详细地址、
        Longitude: "", //经度、
        Latitude: "", //纬度
        ServiceTelephoneNumber: "", // 服务电话
        BusinessTime: "",
        BusinessStartTime: "", // 服务开始时间
        BusinessEndTime: "", // 服务结束时间
        EntityBusinessDate: [], // 营业事假
        Description: "", // 门店简介
        PhotoURLList: [], //  门店照片
        Photo_file_list: [], //  门店照片
        Logo_file: [],
      },
      rules_3: {
        ParentID: [
          { required: true, message: "请选择上级单位", trigger: "change" },
        ],
        IsJoin: [
          { required: true, message: "选择是否加盟店", trigger: "blur" },
        ],
        EntityName: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        IsStore: [
          { required: true, message: "请选择是否为门店", trigger: "change" },
        ],
        IsWarehouse: [
          { required: true, message: "请选择是否为仓库", trigger: "change" },
        ],
        Active: [
          { required: true, message: "请选择有效性", trigger: "change" },
        ],
        selectedOptions: [
          { required: false, message: "请选择地址", trigger: "change" },
        ],
        AddressDetail: [
          { required: false, message: "请输入详细地址", trigger: "change" },
        ],
        Latitude: [
          {
            required: true,
            message: "请选择详细地址",
            trigger: ["blur", "change"],
          },
        ],
      },
      // 编辑员工
      formInline: {
        ID: "",
        user: "",
        region: "",
        JobID: "",
        Name: "",
        PhoneNumber: "",
        Gender: "",
        Birthdate: "",
        Address: "",
        Entity: [],
        EntryDate: "",
        timeData: "",
        TrialExpiryDate: "",
        State: "",
      },
      rules_formInline: {
        ID: [{ required: true, message: "请输入员工编号", trigger: "blur" }],
        Name: [{ required: true, message: "请输入员工编号", trigger: "blur" }],
        PhoneNumber: [
          {
            required: true,
            validator: validate.validPhoneNumber,
            trigger: "blur",
          },
        ],
        Gender: [{ required: true, message: "请选择性别", trigger: "change" }],
        Entity: [
          { required: true, message: "请选择所属单位", trigger: "change" },
        ],
        JobID: [{ required: true, message: "请选择职务", trigger: "change" }],
        State: [
          { required: true, message: "请选择在职状态", trigger: "change" },
        ],
      },

      // 批量设置职务
      dutyRuleForms: {
        duty: "",
      },
      rules_duty: {
        duty: [{ required: true, message: "请选择职务", trigger: "change" }],
      },
      // 批量设置所属单位参数
      EntityData: [],
      options: regionData, // 不带全部选项
      // options: regionDataPlus, // 带全部选项
      gaodeMap: "",
      marker: "",
      State: true, // 是否在职
      JobID: "", // 职位ID
      gdAMap: null,
      showFileList_1: [],
      showFileList_2: [],
      showFileList_3: [],
      showFileList_4: [],
    };
  },

  watch: {
    departmentName(val) {
      this.$refs.tree.filter(val);
    },
    addorganization(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs["refPurchase"].setCheckedKeys([]);
          this.$refs["refSale"].setCheckedKeys([]);
        });
      }
    },
  },

  methods: {
    // //状态显示转换
    formatStatus: function(row) {
      return row.State ? "在职" : "离职";
    },
    /** 转换部门数据结构 */
    normalizer(node) {
      return {
        id: node.ID,
        label: node.EntityName,
        children: node.Child,
      };
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.EntityName.indexOf(value) !== -1;
    },

    contactQuery() {
      this.entityData();
      this.paginations.page = 1;
      this.searchKey = this.tissueSearchKey;
      this.entityId = "";
      this.employeeData();
    },

    // 组织架构
    entityData: function() {
      var that = this;
      that.loading = true;
      var params = {
        SearchKey: that.tissueSearchKey,
      };
      APIEntity.getEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            this.entityList = res.Data;
            // that.entityTreeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 可采购/调拨门店
    storeListData: function() {
      var that = this;
      that.loading = true;
      APIEntity.storeList({ EntityID: this.storeEntityId })
        .then((res) => {
          if (res.StateCode == 200) {
            this.storeList = res.Data;
            // that.entityTreeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 新增组织弹窗
    addEntityModal: function() {
      var that = this;
      that.activeName = "0";
      that.addorganization = true;
      that.ruleForm = {
        IsJoin: false,
        ParentID: null,
        EntityName: "",
        IsStore: false,
        IsWarehouse: false,
        IsOpenOnlineShop: false,
        selectedOptions: "",
        ProvinceCode: "", //省、
        CityCode: "", //市、
        AreaCode: "", //区、
        AddressDetail: "", //详细地址、
        Longitude: "", //经度、
        Latitude: "", //纬度
        EntityLogoURL: "", //门店logo
        Logo_file: [], //门店logo
        ServiceTelephoneNumber: "", // 服务电话
        BusinessTime: "",
        BusinessStartTime: "", // 服务开始时间
        BusinessEndTime: "", // 服务结束时间
        EntityBusinessDate: [], // 营业事假
        Description: "", // 门店简介
        PhotoURLList: [], //  门店照片
        Photo_file_list: [], //  门店照片
      };
      that.rules_2.selectedOptions = [
        { required: false, message: "请选择地址", trigger: "change" },
      ];
      that.rules_2.AddressDetail = [
        { required: false, message: "请输入详细地址", trigger: "change" },
      ];

      that.rules_2.Latitude = [
        { required: false, message: "请选择详细地址", trigger: "change" },
      ];

      that.businessHours.forEach((val) => {
        val.bool = false;
      });

      that.ruleForm.ParentID = null;
      that.organizationData(that.entityList);
      that.GaodeAMapLoaderConf();
      that.storeEntityId = "";
      that.storeListData();
    },
    /**  关闭新增弹窗  */
    addorganizationClose() {
      let that = this;
      that.gaodeMap = null;
    },
    /**    */
    // editOrganizationClose() {
    //   let that = this;
    //   that.gaodeMap = null;
    // },
    /** 修改 是否门店   */
    IsStoreChange(label) {
      let that = this;
      if (label) {
        that.ruleForm.IsWarehouse = true;
      }

      //   console.log(that.$refs["ruleForm"]);
      // that.$nextTick(()=>{
      that.$refs.ruleForm.clearValidate();
      // })

      if (that.ruleForm.IsStore) {
        that.rules_2.selectedOptions = [
          { required: true, message: "请选择地址", trigger: "change" },
        ];
        that.rules_2.AddressDetail = [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ];
        that.rules_2.Latitude = [
          {
            required: true,
            message: "请选择详细地址",
            trigger: ["change", "blur"],
          },
        ];

        // that.rules_3.selectedOptions = [{ required: true, message: "请选择地址", trigger: "change" },];
        // that.rules_3.AddressDetail = [{ required: true, message: "请输入详细地址", trigger: "change" },];
        // that.rules_3.Latitude = [{required: true, message:"请选择详细地址",trigger:["change","blur"] }];
      } else {
        that.rules_2.selectedOptions = [
          { required: false, message: "请选择地址", trigger: "change" },
        ];
        that.rules_2.AddressDetail = [
          { required: false, message: "请输入详细地址", trigger: "change" },
        ];
        that.rules_2.Latitude = [
          {
            required: false,
            message: "请选择详细地址",
            trigger: ["change", "blur"],
          },
        ];

        // that.rules_3.selectedOptions = [{ required: false, message: "请选择地址", trigger: "change" },];
        // that.rules_3.AddressDetail = [{ required: false, message: "请输入详细地址", trigger: "change" },];
        // that.rules_3.Latitude = [{required: false, message:"请选择详细地址",trigger:["change","blur"] }];
      }
    },
    /**  修改是否仓库  */
    IsWarehouseChange() {
      let that = this;
      // that.$refs.ruleForm.clearValidate();
      // if (that.ruleForm.IsStore || that.ruleForm.IsWarehouse) {
      //   that.rules_2.selectedOptions = [
      //     { required: true, message: "请选择地址", trigger: "change" },
      //   ];
      //   that.rules_2.AddressDetail = [
      //     { required: true, message: "请输入详细地址", trigger: "change" },
      //   ];
      // } else {
      //   that.rules_2.selectedOptions = [
      //     { required: false, message: "请选择地址", trigger: "change" },
      //   ];
      //   that.rules_2.AddressDetail = [
      //     { required: false, message: "请输入详细地址", trigger: "change" },
      //   ];
      // }
    },
    /**    */
    IsEditStoreChange(label) {
      let that = this;
      if (label) {
        that.ruleOrganization.IsWarehouse = true;
      }
      that.$refs.ruleOrganization.clearValidate();
      if (that.ruleOrganization.IsStore) {
        that.rules_3.selectedOptions = [
          { required: true, message: "请选择地址", trigger: "change" },
        ];
        that.rules_3.AddressDetail = [
          { required: true, message: "请输入详细地址", trigger: "change" },
        ];
        that.rules_3.Latitude = [
          {
            required: true,
            message: "请选择详细地址",
            trigger: ["change", "blur"],
          },
        ];
      } else {
        that.rules_3.selectedOptions = [
          { required: false, message: "请选择地址", trigger: "change" },
        ];
        that.rules_3.AddressDetail = [
          { required: false, message: "请输入详细地址", trigger: "change" },
        ];

        that.rules_3.Latitude = [
          {
            required: false,
            message: "请选择详细地址",
            trigger: ["change", "blur"],
          },
        ];
      }
    },

    /** 修改省市区   */
    handleChange(value) {
      if (!value) {
        return;
      }
      var that = this;
      that.ruleForm.selectedOptions = value;
      that.ruleForm.ProvinceCode = value[0];
      that.ruleForm.CityCode = value[1];
      that.ruleForm.AreaCode = value[2];

      let ProvinceText = CodeToText[that.ruleForm.ProvinceCode]; // 省 直辖市
      let CityText = CodeToText[that.ruleForm.CityCode]; // 市  、
      let AreaText = CodeToText[that.ruleForm.AreaCode]; // 区
      let address = ProvinceText + CityText + AreaText; //
      if (CityText == "市辖区") {
        //直辖市会有问题 无法跳转到当前行政区
        that.gdAMap.setCity(ProvinceText);
      } else {
        that.gdAMap.setCity(CityText);
      }

      // that.getAddress_location(address)
    },
    /**  高德地图 配置  */
    GaodeAMapLoaderConf() {
      let that = this;
      let cont = that.editOrganization ? "editContainer" : "container";
      /**  设置定图  */
      let gdMap = new gdMaps({
        dom: cont,
        setting: {
          zoom: 11, //设置地图缩放级别PC取值范围为3-18，移动端为3-19
          resizeEnable: true, //是否监控地图容器尺寸变化
        },
      });
      that.gdAMap = gdMap;

      /**  创建地图  */
      gdMap.createAMapLoader().then((AMap) => {
        gdMap.setGdControl(AMap);
        /**  更改地图中心点  */
        // AMap.changeMapCenter([116.397428, 39.90923])

        AMap.on("click", function(val) {
          that.ruleForm.Longitude = val.lnglat.lng;
          that.ruleForm.Latitude = val.lnglat.lat;

          that.ruleOrganization.Longitude = val.lnglat.lng;
          that.ruleOrganization.Latitude = val.lnglat.lat;

          if (placeSearch) {
            placeSearch.clear();
            if (placeSearch.render) {
              placeSearch.render.markerList.clear();
            }
          }

          /**  根据 经纬度 获取 地址信息  */
          gdMap
            .getAddress_geocoder(val.lnglat.lng, val.lnglat.lat)
            .then((option) => {
              if (that.editOrganization) {
                that.ruleOrganization.AddressDetail = option.address;
                // that.updateMarker(that.ruleForm.Longitude, that.ruleForm.Latitude);
                gdMap.addMark({
                  position: [val.lnglat.lng, val.lnglat.lat], //标记中心点
                  icon: false, //false 使用默认的，需要自定义的传入图标路径
                  title: that.ruleOrganization.AddressDetail, //给标记添加一个文章窗口描述
                  clearMark: true, //是否清除之前的点,如果想添加多个点需要设置为false
                });
              } else {
                that.ruleForm.AddressDetail = option.address;
                gdMap.addMark({
                  position: [val.lnglat.lng, val.lnglat.lat], //标记中心点
                  icon: false, //false 使用默认的，需要自定义的传入图标路径
                  title: that.ruleForm.AddressDetail, //给标记添加一个文章窗口描述
                  clearMark: true, //是否清除之前的点,如果想添加多个点需要设置为false
                });
              }
              that.getChinaAreaCode(
                option.province,
                option.city,
                option.district
              );
            })
            .catch((fail) => {});
        });

        if (that.editOrganization) {
          if (that.ruleOrganization.ProvinceCode) {
            let ProvinceText = CodeToText[that.ruleOrganization.ProvinceCode]; // 省 直辖市
            let CityText = CodeToText[that.ruleOrganization.CityCode]; // 市
            let AreaText = CodeToText[that.ruleOrganization.AreaCode]; // 区
            let address =
              ProvinceText +
              CityText +
              AreaText +
              that.ruleOrganization.AddressDetail; //
            gdMap.getAddress_location(address).then((location) => {
              gdMap.changeMapCenter([location.lng, location.lat]);
              gdMap.addMark({
                position: [location.lng, location.lat], //标记中心点
                icon: false, //false 使用默认的，需要自定义的传入图标路径
                title: address, //给标记添加一个文章窗口描述
                clearMark: true, //是否清除之前的点,如果想添加多个点需要设置为false
              });
            });
          } else {
            if (that.ruleOrganization.Latitude) {
              /**  修改地图中心点  */
              gdMap.changeMapCenter([
                that.ruleOrganization.Longitude,
                that.ruleOrganization.Latitude,
              ]);
              /**  t添加标记点  */
              gdMap.addMark({
                position: [
                  that.ruleOrganization.Longitude,
                  that.ruleOrganization.Latitude,
                ], //标记中心点
                icon: false, //false 使用默认的，需要自定义的传入图标路径
                title: that.ruleOrganization.AddressDetail, //给标记添加一个文章窗口描述
                clearMark: true, //是否清除之前的点,如果想添加多个点需要设置为false
              });
              /**  根据 经纬度 获取 地址信息  */
              gdMap
                .getAddress_geocoder(
                  that.ruleOrganization.Longitude,
                  that.ruleOrganization.Latitude
                )
                .then((option) => {
                  if (that.editOrganization) {
                    that.ruleOrganization.AddressDetail = option.address;
                  } else {
                    that.ruleForm.AddressDetail = option.address;
                  }
                  that.getChinaAreaCode(
                    option.province,
                    option.city,
                    option.district
                  );
                })
                .catch((fail) => {});
            }
          }
        }
      });
    },
    /**   返回IP 定位城市信息  */
    showCityInfo() {
      let that = this;
      //实例化城市查询类
      var citysearch = new AMap.CitySearch();
      //自动获取用户IP，返回当前城市
      citysearch.getLocalCity(function(status, result) {
        if (status === "complete" && result.info === "OK") {
          if (result && result.city && result.bounds) {
            var province = result.province; //省 直辖市
            var cityinfo = result.city; // 市
            var citybounds = result.bounds; // 经纬度信息
            that.getChinaAreaCode(province, cityinfo);
          }
        } else {
          // document.getElementById('info').innerHTML = result.info;
        }
      });
    },

    /**  根据 文本获取省市区编码  */
    getChinaAreaCode(province, city, arear) {
      let that = this;
      let provinceCode = TextToCode[province].code;
      let cityStr = city ? city : "市辖区"; //处理直辖市
      let cityinfoCode = TextToCode[province][cityStr].code;
      if (arear) {
        let arearCode = TextToCode[province][cityStr][arear].code;
        if (that.editOrganization) {
          that.ruleOrganization.ProvinceCode = provinceCode;
          that.ruleOrganization.CityCode = cityinfoCode;
          that.ruleOrganization.AreaCode = arearCode;

          that.ruleOrganization.selectedOptions = [
            provinceCode,
            cityinfoCode,
            arearCode,
          ];
        } else {
          that.ruleForm.ProvinceCode = provinceCode;
          that.ruleForm.CityCode = cityinfoCode;
          that.ruleForm.AreaCode = arearCode;
          that.ruleForm.selectedOptions = [
            provinceCode,
            cityinfoCode,
            arearCode,
          ];
        }
      } else {
        let arearCodes = TextToCode[province][cityStr];
        let arears = Enumerable.from(arearCodes)
          .where((val) => {
            return (
              val.key != "全部" && val.key != "市辖区" && val.key != "code"
            );
          })
          .toArray();
        if (arears.length > 0) {
          let arearCode = arears[0].value.code;
          // that.ruleForm.selectedOptions = [provinceCode,cityinfoCode,arearCode];
          if (that.editOrganization) {
            that.ruleOrganization.selectedOptions = [
              provinceCode,
              cityinfoCode,
              arearCode,
            ];
          } else {
            that.ruleForm.selectedOptions = [
              provinceCode,
              cityinfoCode,
              arearCode,
            ];
          }
        }
      }
    },

    /**  编辑门店信息 打开动画结束时的回调   */
    closedEditDialog() {
      if (placeSearch) {
        placeSearch.clear();
      }
      placeSearch = null;
    },

    /** 地点搜索服务插件，提供某一特定地区的位置查询服务   */
    getPlaceSearch() {
      let that = this;
      if (that.editOrganization) {
        placeSearch = that.gdAMap.getPlaceSearch(
          "editPanel",
          that.ruleOrganization.AddressDetail
        );
      } else {
        placeSearch = that.gdAMap.getPlaceSearch(
          "panel",
          that.ruleForm.AddressDetail
        );
      }

      //监听marker/列表的选中事件
      new AMap.event.addListener(placeSearch, "selectChanged", function(
        results
      ) {
        let province = results.selected.data.pname; // 省
        // 市  如果是 【直辖市】 省和 市是相同的  联动策略 将 直辖市先的是 修改为 市辖区
        let city =
          results.selected.data.cityname == province
            ? "市辖区"
            : results.selected.data.cityname; // 市 // 直辖市会有问题
        let arear = results.selected.data.adname; // 区
        that.getChinaAreaCode(province, city, arear);

        if (that.editOrganization) {
          that.ruleOrganization.AddressDetail = results.selected.data.address;
          that.ruleOrganization.Longitude = results.selected.data.location.lng;
          that.ruleOrganization.Latitude = results.selected.data.location.lat;
        } else {
          that.ruleForm.AddressDetail = results.selected.data.address;
          that.ruleForm.Longitude = results.selected.data.location.lng;
          that.ruleForm.Latitude = results.selected.data.location.lat;
        }
      });
    },

    // 组织架构输入框搜索(弹窗数据)
    getAllEntity() {
      var that = this;
      that.loading = true;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.entityTreeList = res.Data;
            that.entityList = res.Data;
            if (that.entityList.length > 0) {
              that.entityId = that.entityList[0].ID;
              that.departmentTitle = that.entityList[0].EntityName;
              that.departmentParentID = that.entityList[0].ParentID;
              that.ruleForm.region = that.entityList[0].ID;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },

    /**    */
    handleRemove(file, type) {
      if (file && file.status !== "success") return;
      let that = this;
      switch (type) {
        case 10:
          that.ruleForm.Logo_file = [];
          break;
        case 20:
          let index = that.ruleForm.Photo_file_list.findIndex(
            (item) => item.url == file.url
          );
          that.ruleForm.Photo_file_list.splice(index, 1);

          that.showFileList_2.splice(index, 1);
          break;

        case 30:
          that.ruleOrganization.Logo_file = [];
          break;
        case 40:
          let indx = that.ruleOrganization.Photo_file_list.findIndex(
            (item) => item.uid == file.uid
          );

          that.ruleOrganization.Photo_file_list.splice(indx, 1);
          that.showFileList_4.splice(indx, 1);
          break;
      }
    },
    /**  上传logo  */
    beforeAvatarUpload(file, isEdit) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        that.$message.error("上传头像图片大小不能超过 3MB!");
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(evt) {
        let base64 = evt.target.result;
        that.$nextTick(function() {
          if (isEdit) {
            that.ruleOrganization.Logo_file = [{ name: "", url: base64 }];
            that.showFileList_3 = [base64];
          } else {
            that.ruleForm.Logo_file = [{ name: "", url: base64 }];
            that.showFileList_1 = [base64];
          }
        });
      };
      return false;
    },
    /**  上传 门店照片  */
    storePhotoBeforeAvatarUpload(file, isEdit) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        that.$message.error("上传头像图片大小不能超过 3MB!");
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function(evt) {
        let base64 = evt.target.result;
        that.$nextTick(function() {
          if (isEdit) {
            that.ruleOrganization.Photo_file_list.push({ url: base64 });
            that.showFileList_4.push(base64);
          } else {
            that.ruleForm.Photo_file_list.push({ name: "", url: base64 });
            that.showFileList_2.push(base64);
          }
        });
      };
      return false;
    },
    // 选择营业时间
    selBusinessHours(e) {
      const that = this;
      const index = e;
      that.businessHours[index].bool = !that.businessHours[index].bool;
    },
    //提交新增组织
    addEntity() {
      var that = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          that.ruleForm.ProvinceCode = that.ruleForm.selectedOptions[0];
          that.ruleForm.CityCode = that.ruleForm.selectedOptions[1];
          that.ruleForm.AreaCode = that.ruleForm.selectedOptions[2];
          that.ruleForm.BusinessStartTime = that.ruleForm.BusinessTime
            ? date.formatDate.format(that.ruleForm.BusinessTime[0], "hh:mm:ss")
            : "";
          that.ruleForm.BusinessEndTime = that.ruleForm.BusinessTime
            ? date.formatDate.format(that.ruleForm.BusinessTime[1], "hh:mm:ss")
            : "";
          that.ruleForm.EntityLogoURL =
            that.ruleForm.Logo_file && that.ruleForm.Logo_file.length > 0
              ? that.ruleForm.Logo_file[0].url
              : "";
          that.ruleForm.EntityBusinessDate = Enumerable.from(that.businessHours)
            .where((i) => {
              return i.bool;
            })
            .select((val) => ({
              Data: val.id + 1,
            }))
            .toArray();

          that.ruleForm.PhotoURLList = Enumerable.from(
            that.ruleForm.Photo_file_list
          )
            .select((val) => ({
              PhotoURL: val.url,
            }))
            .toArray();
          var params = {
            PurchaseEntityID: this.$refs["refPurchase"].getCheckedKeys(),
            AllocationEntityID: this.$refs["refSale"].getCheckedKeys(),
          };
          let para = Object.assign(params, that.ruleForm);
          console.log(
            "🚀 ~ file: employee.vue ~ line 2014 ~ this.$refs.ruleForm.validate ~ para",
            para
          );
          APIEntity.createEntity(para)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("保存成功");
                that.addorganization = false;
                that.entityData();
                that.getAllEntity();
                that.$refs["ruleForm"].resetFields();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function() {
              that.modalLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    /**  请求网店信息  */
    getOnlineEntityByInfo(ID) {
      let that = this;
      let param = {
        ID: ID,
      };
      APIEntity.getOnlineEntityByID(param)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ruleOrganization.EntityLogoURL = res.Data.EntityLogoURL; // logo
            that.ruleOrganization.IsJoin = res.Data.IsJoin;
            // that.ruleOrganization.Logo_file = res.Data.EntityLogoURL?[{name:"",url:res.Data.EntityLogoURL}]:[]; // logo
            utils.getCanvasBase64(res.Data.EntityLogoURL).then(
              function(base64) {
                // 这里拿到的是转换后的base64地址，可以做其他操作
                that.ruleOrganization.Logo_file.push({ name: "", url: base64 });
                that.showFileList_3 = [base64];
              },
              function(err) {
                console.log(err);
              }
            );

            that.ruleOrganization.ServiceTelephoneNumber = res.Data
              .ServiceTelephoneNumber
              ? res.Data.ServiceTelephoneNumber
              : ""; // 服务电话

            let startTime = res.Data.BusinessStartTime
              ? new Date(
                  date.formatDate.format(new Date(), "YYYY-MM-DD") +
                    " " +
                    res.Data.BusinessStartTime
                )
              : new Date();
            let endTime = res.Data.BusinessEndTime
              ? new Date(
                  date.formatDate.format(new Date(), "YYYY-MM-DD") +
                    " " +
                    res.Data.BusinessEndTime
                )
              : new Date();

            that.ruleOrganization.BusinessTime = [startTime, endTime];
            that.ruleOrganization.BusinessStartTime =
              res.Data.BusinessStartTime; //开始时间
            that.ruleOrganization.BusinessEndTime = res.Data.BusinessEndTime; //结束时间
            that.ruleOrganization.Description = res.Data.Description; //门店简介

            res.Data.PhotoURLList.forEach((val) => {
              utils.getCanvasBase64(val.PhotoURL).then(
                function(base64) {
                  // 这里拿到的是转换后的base64地址，可以做其他操作
                  that.ruleOrganization.Photo_file_list.push({
                    name: "",
                    url: base64,
                  });
                  that.showFileList_4.push(base64);
                },
                function(err) {
                  console.log(err);
                }
              );
            });

            that.businessHours.forEach((val) => {
              res.Data.EntityBusinessDate.forEach((i) => {
                if (val.id == i.Data - 1) {
                  val.bool = true;
                }
              });
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },
    //组织架构列表切换
    handleNodeClick(obj) {
      var that = this;
      that.addstaff = false;
      that.addorganization = false;
      that.entityId = obj.ID;
      that.departmentTitle = obj.EntityName;
      that.departmentParentID = obj.ParentID;
      that.ruleForm.region = obj.ID;

      this.paginations.page = 1;
      this.searchKey = "";
      that.employeeData();
    },

    // 组织架构拖拽
    // 拖拽成功完成时触发的事件
    handleDrop(draggingNode, dropNode, dropType) {
      var moveEntityID = draggingNode.data.ID;
      var destParentID = "";
      var beforeEntityID = "";

      if (dropType == "inner") {
        destParentID = dropNode.data.ID;
        dropNode.data.Child.forEach(function(item, index) {
          if (item.ID == draggingNode.data.ID) {
            if (index > 0) {
              beforeEntityID = dropNode.data.Child[index - 1].ID;
            }
          }
        });
      } else if (dropType == "before") {
        destParentID = dropNode.parent.data.ID;
        dropNode.parent.data.Child.forEach(function(item, index) {
          if (item.ID == draggingNode.data.ID) {
            if (index > 0) {
              beforeEntityID = dropNode.parent.data.Child[index - 1].ID;
            }
          }
        });
      } else {
        beforeEntityID = dropNode.data.ID;
        destParentID = dropNode.parent.data.ID;
      }
      this.dropEntity(moveEntityID, destParentID, beforeEntityID);
    },
    allowDrag(node) {
      return node.data.ID != 1;
    },
    allowDrop(draggingNode, dropNode, type) {
      return !((type == "prev" || type == "next") && dropNode.data.ID == 1);
    },
    // 顺序切换
    dropEntity: function(moveEntityID, destParentID, beforeEntityID) {
      var that = this;
      var params = {
        MoveEntityID: moveEntityID,
        DestParentID: destParentID,
        BeforeEntityID: beforeEntityID,
      };
      APIEntity.moveEntity(params).then((res) => {
        if (res.StateCode == 200) {
          that.entityData();
          that.entityDefaultExpandedKeys = [params.MoveEntityID];
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    /**    */
    searchEmployeeData() {
      var that = this;
      that.paginations.page = 1;
      that.employeeData();
    },
    // 员工列表
    employeeData: function() {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.paginations.page,
        SearchKey: that.searchKey,
        EntityID: that.entityId,
        State: that.State,
        JobID: that.JobID,
      };
      APIEmployee.getEmployee(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
    //分页切换
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.employeeData();
    },
    // 员工职务
    getJobType: function() {
      var that = this;
      var params = {
        JobTypeName: that.JobTypeName,
      };
      APIJob.getJobJobtypeAll(params).then((res) => {
        if (res.StateCode == 200) {
          that.jobTypeData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 新增员工弹窗
    addEmployeeModal: function() {
      var that = this;
      that.ruleForms.Entity = [];
      that.ruleForms.Entity.push({
        EntityID: that.entityId,
        EntityName: that.departmentTitle,
        IsPrimaryEntity: true,
      });
      that.isEmployee = 1;
      that.addstaff = true;
      that.showTip = true;
      that.getJobType();
    },
    // 修改员工所属单位
    addModifyEntity() {
      var that = this;
      that.addBelongToDia = true;
      that.checkedData = Enumerable.from(this.ruleForms.Entity)
        .select((val) => ({
          ID: val.EntityID,
          IsPrimaryEntity: val.IsPrimaryEntity,
          EntityName: val.EntityName,
          isMouseenter: false,
        }))
        .toArray();
      that.treeDefaultCheckedKeys = Enumerable.from(this.ruleForms.Entity)
        .select((val) => val.EntityID)
        .toArray();
    },
    // 员工所在部门隐藏
    clickAddEmpBelongToCancalBtn() {
      this.addBelongToDia = false;
      this.departmentName = "";
      this.checkedData = [];
      this.$refs.tree.setCheckedNodes(this.checkedData);
    },

    // 获取复选框选中事件
    getCheckedData(val, val1) {
      var that = this;
      if (typeof val.IsPrimaryEntity != "undefined") {
        val.isMouseenter = false;
        val.IsPrimaryEntity = false;
      }
      that.checkedData = val1.checkedNodes;
      if (that.checkedData.length > 0) {
        var masterEntity = Enumerable.from(this.checkedData)
          .where(function(i) {
            return i.IsPrimaryEntity == true;
          })
          .toArray();
        if (masterEntity.length <= 0) {
          that.$set(that.checkedData[0], "isMouseenter", false);
          that.$set(that.checkedData[0], "IsPrimaryEntity", true);
        }
      }
      that.checkedData.forEach(function(item) {
        if (!item.IsPrimaryEntity) {
          that.$set(item, "isMouseenter", false);
          that.$set(item, "IsPrimaryEntity", false);
        }
      });
    },
    // 删除选中的部门
    removeSection: function(index) {
      var that = this;
      that.checkedData.splice(index, 1);
      if (that.checkedData.length > 0) {
        var masterEntity = Enumerable.from(this.checkedData)
          .where(function(i) {
            return i.IsPrimaryEntity == true;
          })
          .toArray();
        if (masterEntity.length <= 0) {
          that.$set(that.checkedData[0], "isMouseenter", false);
          that.$set(that.checkedData[0], "IsPrimaryEntity", true);
        }
      }
      this.$refs.tree.setCheckedNodes(that.checkedData);
    },
    //鼠标显示
    dataDetails: function(item, index) {
      var that = this;
      if (!item.IsPrimaryEntity) {
        that.checkedData[index].isMouseenter = true;
      }
    },
    // 鼠标隐藏
    hiddenDetail: function(index) {
      var that = this;
      that.checkedData[index].isMouseenter = false;
    },
    // 设为主部门
    setSection: function(index) {
      var that = this;
      that.checkedData.forEach(function(item) {
        item.IsPrimaryEntity = false;
      });
      that.checkedData[index].IsPrimaryEntity = true;
    },
    // 增加员工所属单位弹出框确认按钮事件
    clickAddEmpBelongToBtn() {
      var that = this;
      // 获取所选择的员工ID
      var arr = [];
      for (let i = 0; i <= that.multipleSelection.length - 1; i++) {
        arr.push(that.multipleSelection[i].ID);
      }
      that.belongEmployeeID = arr;
      if (that.checkedData.length > 0) {
        //获取选择的所属单位信息
        var Entity = [];
        for (var i = 0; i < that.checkedData.length; i++) {
          var isPrimaryObj = {};
          isPrimaryObj.EntityID = that.checkedData[i].ID;
          isPrimaryObj.IsPrimaryEntity = that.checkedData[i].IsPrimaryEntity;
          isPrimaryObj.EntityName = that.checkedData[i].EntityName;
          Entity.push(isPrimaryObj);
        }
        if (that.isEmployee == 1) {
          that.ruleForms.Entity = Entity;
        } else if (that.isEmployee == 2) {
          that.formInline.Entity = Entity;
        }
        that.addBelongToDia = false;
      } else {
        that.$message.error("至少选择一个部门");
      }
    },

    // 所属单位删除
    handleClose(tag) {
      this.ruleForms.Entity.splice(this.ruleForms.Entity.indexOf(tag), 1);
    },

    // 提交新增员工
    submitAddEmployee() {
      var that = this;
      this.$refs["ruleForms"].validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          var params = {
            ID: that.ruleForms.empId,
            Name: that.ruleForms.name,
            Alias: that.ruleForms.Alias,
            PhoneNumber: that.ruleForms.phone,
            JobID: that.ruleForms.duty,
            Gender: that.ruleForms.Gender,
            IDNumber: "",
            Birthdate: "",
            Address: "",
            EntryDate: "",
            TrialExpiryDate: "",
            ContractStartDate: "",
            ContractEndDate: "",
            Entity: that.ruleForms.Entity,
          };
          APIEmployee.createEmployee(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("成功");
                that.addstaff = false;
                that.employeeData();
                that.$refs["ruleForms"].resetFields();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function() {
              that.modalLoading = false;
            });
        }
      });
    },
    resetForm() {
      //formName
      var that = this;
      this.$refs["ruleForms"].resetFields();
      that.addstaff = false;
    },
    //编辑员工
    toStaffDetails(row) {
      var that = this;
      that.empEditState = true;
      that.isEmployee = 2;
      that.checkedData = [];
      that.formInline = Object.assign({}, row);
      if (row.ContractEndDate == null) {
        that.timeData = [];
      } else {
        that.timeData = [
          that.formInline.ContractStartDate,
          that.formInline.ContractEndDate,
        ];
      }
      that.getJobType();
    },
    // 员工编辑所属单位删除
    handleCloseEdit(tag) {
      this.formInline.Entity.splice(this.formInline.Entity.indexOf(tag), 1);
    },
    // 修改员工所属单位
    editModifyEntity() {
      var that = this;
      this.addBelongToDia = true;
      this.checkedData = Enumerable.from(this.formInline.Entity)
        .select((val) => ({
          ID: val.EntityID,
          IsPrimaryEntity: val.IsPrimaryEntity,
          EntityName: val.EntityName,
          isMouseenter: false,
        }))
        .toArray();
      that.treeDefaultCheckedKeys = Enumerable.from(this.formInline.Entity)
        .select((val) => val.EntityID)
        .toArray();
    },
    // 员工编辑保存
    clickSaveBtn() {
      var that = this;
      this.$refs["formInline"].validate((valid) => {
        if (valid) {
          that.modalLoading = true;
          var ContractStartDate = "";
          var ContractEndDate = "";
          if (that.timeData.length > 0) {
            ContractStartDate = that.timeData[0];
            ContractEndDate = that.timeData[1];
          }
          var params = {
            ID: that.formInline.ID,
            Name: that.formInline.Name,
            PhoneNumber: that.formInline.PhoneNumber,
            JobID: that.formInline.JobID,
            State: that.formInline.State,
            Gender: that.formInline.Gender,
            IDNumber: that.formInline.IDNumber,
            Birthdate: that.formInline.Birthdate,
            Address: that.formInline.Address,
            EntryDate: that.formInline.EntryDate,
            TrialExpiryDate: that.formInline.TrialExpiryDate,
            ContractStartDate: ContractStartDate,
            ContractEndDate: ContractEndDate,
            Entity: that.formInline.Entity,
            Alias: that.formInline.Alias,
            Remark: that.formInline.Remark,
            SocialSecurityPaymentDate:
              that.formInline.SocialSecurityPaymentDate,
          };
          APIEmployee.updateEmployee(params)
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.success("保存成功");
                that.empEditState = false;
                that.employeeData();
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function() {
              that.modalLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 右边表格选择
    handleSelectionChange(val) {
      var that = this;
      var arr = [];
      that.multipleSelection = val;
      that.employeeNum = that.multipleSelection.length;
      for (let i = 0; i <= that.multipleSelection.length - 1; i++) {
        arr.push(that.multipleSelection[i].ID);
      }
      that.EmployeeID = arr;
    },

    //批量设置职务下拉点击事件
    handleSelect(val) {
      var that = this;
      if (that.employeeNum == 0) {
        that.$message.error({
          message: "请选择员工",
          duration: 2000,
        });
        return false;
      }
      if (val == "duty") {
        that.getJobType();
        that.dutyRuleForms = {
          duty: "",
        };
        that.dialogVisible = true;
      } else if (val == "belongToUnit") {
        this.getAllEntity();
        that.departmentName = "";
        that.checkedData = [];
        if (this.$refs.tree) {
          this.$refs.tree.setCheckedNodes(this.checkedData);
        }
        that.belongto = true;
      }
    },

    //区域切换
    handleCommand(val) {
      console.log(val);
      var that = this;
      that.addstaff = false;
      that.activeName = "0";
      that.businessHours.forEach((val) => {
        val.bool = false;
      });
      if (val == "add") {
        that.$nextTick(() => {
          that.$refs.ruleForm.clearValidate();
        });
        that.ruleForm = {
          IsJoin: false,
          ParentID: null,
          EntityName: "",
          IsStore: false,
          IsWarehouse: false,
          selectedOptions: "",
          ProvinceCode: "", //省、
          CityCode: "", //市、
          AreaCode: "", //区、
          AddressDetail: "", //详细地址、
          Longitude: "", //经度、
          Latitude: "", //纬度
        };
        that.rules_2.selectedOptions = [
          { required: false, message: "请选择地址", trigger: "change" },
        ];
        that.rules_2.AddressDetail = [
          { required: false, message: "请输入详细地址", trigger: "change" },
        ];

        that.rules_2.Latitude = [
          {
            required: false,
            message: "请选择详细地址",
            trigger: ["change", "blur"],
          },
        ];
        that.addorganization = true;
        that.ruleForm.ParentID = null;
        that.organizationData(that.entityList);
        that.storeEntityId = "";
        that.storeListData();
      } else {
        (that.ruleOrganization.Photo_file_list = []), //  门店照片
          (that.ruleOrganization.Logo_file = []),
          that.organizationData(that.entityList);
        that.$nextTick(() => {
          that.$refs.ruleOrganization.clearValidate();
        });

        if (that.ruleOrganization.IsStore) {
          that.rules_3.selectedOptions = [
            { required: true, message: "请选择地址", trigger: "change" },
          ];
          that.rules_3.AddressDetail = [
            { required: true, message: "请输入详细地址", trigger: "change" },
          ];
          that.rules_3.Latitude = [
            {
              required: true,
              message: "请选择详细地址",
              trigger: ["change", "blur"],
            },
          ];
        } else {
          that.rules_3.selectedOptions = [
            { required: false, message: "请选择地址", trigger: "change" },
          ];
          that.rules_3.AddressDetail = [
            { required: false, message: "请输入详细地址", trigger: "change" },
          ];
          that.rules_3.Latitude = [
            {
              required: false,
              message: "请选择详细地址",
              trigger: ["change", "blur"],
            },
          ];
        }
        that.getOnlineEntityByInfo(that.entityId);
        that.storeEntityId = that.entityId;
        that.storeListData();
        that.editOrganization = true;
        that.$nextTick(() => {
          that.getEntityID(that.entityId);
        });
      }
      that.GaodeAMapLoaderConf();
    },
    //回显调拨采购  接口
    async getEntityID(EntityID) {
      APIEntity.purchaseEntity({ EntityID }).then((res) => {
        if (res.StateCode == 200) {
          this.$refs["refEditPur"].setCheckedKeys(res.Data);
        } else {
          that.$message.error(res.Message);
        }
      });
      APIEntity.allocationEntity({ EntityID }).then((res) => {
        if (res.StateCode == 200) {
          this.$refs["refEditSale"].setCheckedKeys(res.Data);
        }
      });
    },
    // 编辑组织参数
    organizationData: function(list) {
      var that = this;
      list.forEach(function(item) {
        if (item.ID == that.entityId) {
          that.ruleOrganization.ID = item.ID;
          that.ruleOrganization.ParentID = item.ParentID;
          that.ruleOrganization.EntityName = item.EntityName;
          that.ruleOrganization.IsStore = item.IsStore;
          that.ruleOrganization.IsWarehouse = item.IsWarehouse;
          that.ruleOrganization.Active = item.Active;
          that.ruleForm.ParentID = item.ID;
          that.ruleOrganization.selectedOptions = [
            item.ProvinceCode,
            item.CityCode,
            item.AreaCode,
          ];
          that.ruleOrganization.ProvinceCode = item.ProvinceCode; //省、
          that.ruleOrganization.CityCode = item.CityCode; //市、
          that.ruleOrganization.AreaCode = item.AreaCode; //区、
          that.ruleOrganization.AddressDetail = item.AddressDetail; //详细地址、
          that.ruleOrganization.Longitude = item.Longitude; //经度、
          that.ruleOrganization.Latitude = item.Latitude; //纬度
        }
        if (item.Child) {
          that.organizationData(item.Child);
        }
      });
    },
    // 编辑组织
    submitoOrganization: function() {
      var that = this;
      this.$refs.ruleOrganization.validate((valid) => {
        if (valid) {
          that.modalLoading = true;

          that.ruleOrganization.BusinessStartTime = that.ruleOrganization
            .BusinessTime
            ? that.ruleOrganization.BusinessTime[0]
            : "";
          that.ruleOrganization.BusinessEndTime = that.ruleOrganization
            .BusinessTime
            ? that.ruleOrganization.BusinessTime[1]
            : "";

          that.ruleOrganization.EntityLogoURL =
            that.ruleOrganization.Logo_file &&
            that.ruleOrganization.Logo_file.length > 0
              ? that.ruleOrganization.Logo_file[0].url
              : "";
          that.ruleOrganization.EntityBusinessDate = Enumerable.from(
            that.businessHours
          )
            .where((i) => {
              return i.bool;
            })
            .select((val) => ({
              Data: val.id + 1,
            }))
            .toArray();

          that.ruleOrganization.PhotoURLList = Enumerable.from(
            that.ruleOrganization.Photo_file_list
          )
            .select((val) => ({
              PhotoURL: val.url,
            }))
            .toArray();
          var params = {
            PurchaseEntityID: this.$refs["refEditPur"].getCheckedKeys(),
            AllocationEntityID: this.$refs["refEditSale"].getCheckedKeys(),
          };
          let para = Object.assign(params, that.ruleOrganization);
          console.log(
            "🚀 ~ file: employee.vue ~ line 2290 ~ this.$refs.ruleOrganization.validate ~ para",
            para
          );

          APIEntity.updateEntity(para)
            .then(function(res) {
              if (res.StateCode === 200) {
                that.entityData();
                that.entityDefaultExpandedKeys = [para.ID];
                that.handleNodeClick(para);
                that.$message.success({
                  message: "编辑成功",
                });
                that.$refs["ruleOrganization"].resetFields();
              } else {
                that.$message.error({
                  showClose: true,
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function() {
              that.editOrganization = false;
              that.modalLoading = false;
            });
        }
      });
    },
    // 批量设置职务弹出框确定按钮点击事件
    clickSureBtn() {
      var that = this;
      that.belongto = false;
      that.ruleForms.Entity = that.checkedData;
      that.$refs["dutyRuleForms"].validate((valid) => {
        if (valid) {
          that.updateEmployeeJobTypeBatch();
        }
      });
    },

    // 批量设置职务接口
    updateEmployeeJobTypeBatch: function() {
      var that = this;
      that.modalLoading = true;
      var params = {
        EmployeeID: that.EmployeeID, // 员工ID数组
        JobTypeID: that.dutyRuleForms.duty, // 职务ID
      };
      APIEmployee.updateEmployeeJobTypeBatch(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.dialogVisible = false;
            that.employeeData();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },

    // 批量设置所属单位弹出框确认按钮事件
    clickBelongToSureBtn() {
      var that = this;
      if (that.employeeNum > 0) {
        // 获取选择的所属单位信息
        var Entity = [];
        for (var i = 0; i < that.checkedData.length; i++) {
          var isPrimaryObj = {};
          isPrimaryObj.EntityID = that.checkedData[i].ID;
          isPrimaryObj.IsPrimaryEntity = that.checkedData[i].IsPrimaryEntity;
          isPrimaryObj.EntityName = that.checkedData[i].EntityName;
          Entity.push(isPrimaryObj);
        }
        that.EntityData = Entity;
        that.updateEmployeeEntityBatch();
      }
    },
    // 批量更新员工所属单位接口
    updateEmployeeEntityBatch: function() {
      var that = this;
      that.loading = true;
      var params = {
        EmployeeID: that.EmployeeID, // 员工ID数组
        Entity: that.EntityData, // 职务ID
      };
      APIEmployee.updateEmployeeEntityBatch(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.belongto = false;
            that.employeeData();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    /** 数据导出 */
    downloadExcel() {
      var that = this;
      var params = {
        PageNum: that.paginations.page,
        SearchKey: that.searchKey,
        EntityID: that.entityId,
        State: that.State,
        JobID: that.JobID,
      };

      that.downloadLoading = true;
      APIEmployee.exportEmployeeExce(params)
        .then((res) => {
          this.$message.success({
            message: "正在导出",
            duration: "4000",
          });
          const link = document.createElement("a");
          let blob = new Blob([res], { type: "application/octet-stream" });
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.download = "通讯录.xlsx"; //下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .finally(function() {
          that.downloadLoading = false;
        });
    },
    // 查看大图
    DialogPreview(file) {
      document.getElementById(file.uid).click();
    },
  },

  mounted() {
    var that = this;
    that.employeeExcel = permission.permission(
      that.$route.meta.Permission,
      "KHS-Entity-Employee-Export"
    );
    that.getAllEntity();
    that.employeeData();
    that.getJobType();
  },
};
</script>

<style lang="scss">
.Employee {
  padding: 0px;
  height: 100%;
  .addorganizationClass {
    .el-upload-list--picture-card .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
    .el-upload.el-upload--picture-card {
      width: 100px !important;
      height: 100px !important;
      line-height: 115px;
    }
    .el_scrollbar_height {
      height: 60vh;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
      #container {
        position: relative;
        width: 600px;
        height: 320px;
      }
      #panel {
        position: absolute;
        background-color: white;
        max-height: 100%;
        overflow-y: auto;
        top: 0px;
        right: 30px;
        width: 200px;
        z-index: 101;
      }

      #editContainer {
        position: relative;
        width: 600px;
        height: 320px;
      }
      #editPanel {
        position: absolute;
        background-color: white;
        max-height: 100%;
        overflow-y: auto;
        top: 0px;
        right: 30px;
        width: 200px;
        z-index: 101;
      }
    }
  }
  .el_scrollbar_height_dept {
    height: 55vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
</style>
