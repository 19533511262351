<template>
  <div class="HomeDecoration" v-loading="loading">
    <div class="dis_flex">
      <div style="width: 315px">
        <el-scrollbar style="height: 78vh">
          <div class="dis_flex flex_dir_row flex_wrap">
            <div
              class="border_bottom text_center color_666 font_14"
              style="height: 40px; width: 100%; line-height: 40px"
            >
              <i class="el-icon-s-grid"></i> 基本组件
            </div>
            <div
              v-for="(item, index) in ComponentData.BaseComponent"
              :key="index"
              style="width: 100px; height: 100px"
              class="
                dis_flex
                flex_dir_column flex_y_center flex_x_center
                border_bottom
              "
              :style="{
                'border-right': (index + 1) % 3 != 0 ? 'solid 1px #eeeeee' : '',
              }"
              @click="addComponent(item)"
            >
              <el-image
                style="height: 28px; width: 28px"
                :src="
                  require('../../../assets/img/homeConf/' + item.Code + '.png')
                "
              ></el-image>
              <span class="font_14 color_666 martp_5">{{ item.Name }}</span>
            </div>
          </div>
          <div class="dis_flex flex_dir_row flex_wrap">
            <div
              class="border_bottom border_top text_center color_666 font_14"
              style="height: 40px; width: 100%; line-height: 40px"
            >
              <i class="el-icon-s-grid"></i> 其他组件
            </div>
            <div
              v-for="(item, index) in ComponentData.OtherComponent"
              :key="index"
              style="width: 100px; height: 100px"
              class="
                dis_flex
                flex_dir_column flex_y_center flex_x_center
                border_bottom
              "
              :style="{
                'border-right': (index + 1) % 3 != 0 ? 'solid 1px #eeeeee' : '',
              }"
              @click="addComponent(item)"
            >
              <el-image
                style="height: 28px; width: 28px"
                :src="
                  require('../../../assets/img/homeConf/' + item.Code + '.png')
                "
              ></el-image>
              <span class="font_14 color_666 martp_5">{{ item.Name }}</span>
            </div>
          </div>
        </el-scrollbar>
      </div>

      <div class="flex_box back_f8">
        <div
          class="homemodel border position_relative"
          style="margin-top: 20px"
        >
          <div
            class="position_absolute backfff"
            style="top: 0px; left: 0px; right: 0px; z-index: 19"
          >
            <el-image
              :src="
                require('../../../assets/img/homeConf/PhoneStatus Bar2.png')
              "
            ></el-image>
            <!-- 门店 -->
            <div
              class="
                top
                backfff
                font_16
                color_333
                padlt_10
                dis_flex
                flex_y_center
              "
            >
              <span class="font_14 color_333">{{ EntityName }}</span>
              <div
                class="toggleClick dis_flex flex_y_center flex_x_center marlt_5"
              >
                <el-image
                  style="width: 15px; height: 13px"
                  :src="require('@/assets/img/store/toggleClick.png')"
                ></el-image>
              </div>
            </div>
            <!-- 搜索框 -->
            <div class="pad_10 backfff">
              <div
                class="
                  search-top
                  font_12
                  color_999
                  dis_flex
                  flex_dir_row flex_y_center
                "
              >
                <i class="el-icon-search marlt_5 font_14"></i>
                <div class="font_12 color_999 marlt_5">
                  输入商品名称进行搜索
                </div>
              </div>
            </div>
          </div>
          <el-scrollbar style="height: 70vh">
            <div style="margin-top: 90px">
              <draggable
                v-model="comps"
                chosenClass="chosen"
                group="people1"
                animation="1000"
              >
                <div
                  class="position_relative"
                  :style="comp.isEdit || comp.isShowBoder ? editStyle() : ''"
                  v-for="(comp, index) in comps"
                  :key="comp.Code + index"
                  @mouseenter="compontenMouse(comp)"
                  @mouseleave="compontenMouseLeave(comp)"
                  @click="selectComponentClick(comp)"
                >
                  <i
                    v-show="comp.isEdit"
                    @click="deleteComponent(index)"
                    class="el-icon-circle-close position_absolute font_18"
                    style="right: 0px; top: 0px; color: #07bcfd; z-index: 99"
                  ></i>

                  <component
                    v-show="comp.Code == 'Carousel'"
                    :is="HomeConf[comp.Code]"
                    :bannerList="comp.Conf.Carousel_file_list"
                  ></component>

                  <component
                    v-show="comp.Code == 'GoodsList'"
                    :is="HomeConf[comp.Code]"
                    :HotGoodsList="comp.Conf.HotGoodsConf.GoodsList"
                    :GoodsConf="comp.Conf.HotGoodsConf.Conf"
                  ></component>

                  <component
                    v-show="comp.Code == 'EntityInfo'"
                    :is="HomeConf[comp.Code]"
                    :StoreShowInfo="comp.Conf.EntityInfoConf"
                  ></component>

                  <component
                    v-show="comp.Code == 'RichText'"
                    :is="HomeConf[comp.Code]"
                    :RichTextContent="comp.Conf.RichText"
                  ></component>

                  <component
                    v-show="comp.Code == 'AuxiliaryBlank'"
                    :is="HomeConf[comp.Code]"
                    :Color="comp.Conf.AuxiliaryBlank.Color"
                    :Height="comp.Conf.AuxiliaryBlank.Height"
                  ></component>

                  <component
                    v-show="comp.Code == 'AuxiliaryLine'"
                    :is="HomeConf[comp.Code]"
                    :AuxiliaryLineConf="comp.Conf.AuxiliaryLine"
                  ></component>
                </div>
              </draggable>
            </div>
          </el-scrollbar>
          <div
            class="
              dis_flex
              flex_dir_row flex_x_around
              pad_10
              border_box border_top
            "
            style="background-color: white; width: 100%; height: 49px"
          >
            <div class="dis_flex flex_dir_column flex_y_center">
              <i class="el-icon-s-home font_20" style="color: #1e9efa"></i>
              <span class="font_12" style="color: #1e9efa">首页</span>
            </div>
            <div class="dis_flex flex_dir_column flex_y_center">
              <i class="el-icon-user-solid font_20 color_999"></i>
              <span class="font_12 color_666">我的</span>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 430px">
        <el-scrollbar style="height: 78vh">
          <!-- banner -->
          <div v-show="SelectComponent.Code === 'Carousel'">
            <div class="switchBox pad_0_10 dis_flex flex_y_center">
              <span class="font_14 color_333"
                >轮播图<span class="marlt_10 font_14 color_999"
                  >最多上传五张，建议尺寸710*278px</span
                ></span
              >
            </div>
            <div class="martp_10 flex_wrap pad_10">
              <draggable
                v-model="SelectComponent.Conf.Carousel_file_list"
                animation="1000"
                class=""
              >
                <div
                  v-for="(item, index) in SelectComponent.Conf
                    .Carousel_file_list"
                  :key="index"
                  class="dis_flex flex_dir_row marbm_10"
                >
                  <el-image
                    class="swiperImage"
                    :src="item.url"
                    fit="cover"
                  ></el-image>
                  <div class="dis_flex flex_dir_column flex_x_center">
                    <span
                      v-if="item.goodsID && item.goodsID != ''"
                      class="color_666 font_13 marlt_5 martp_10"
                    >
                      链接:
                      <span class="marlt_5">
                        <span class="swiperGoods font_12">
                          {{ item.goodsTypeName }} | {{ item.goodsName }}
                          <i
                            class="el-icon-close"
                            @click="deleteSwiperGoods(index)"
                          ></i>
                        </span>
                      </span>
                    </span>

                    <el-dropdown szie="small" class="martp_10">
                      <span
                        v-if="item.goodsID && item.goodsID != ''"
                        class="color_main font_13 marlt_5"
                        >修改</span
                      >
                      <span v-else class="color_666 font_13 marlt_5">
                        链接:
                        <span class="color_main marlt_5">链接商品</span></span
                      >
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          @click.native="addGoods('project', index, false)"
                          >项目</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addGoods('product', index, false)"
                          >产品</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addGoods('generalCard', index, false)"
                          >通用次卡</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addGoods('timeCard', index, false)"
                          >时效卡</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addGoods('saveCard', index, false)"
                          >储值卡</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addGoods('packageCard', index, false)"
                          >套餐卡</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <i
                    class="el-icon-error color_666 font_20"
                    @click="deleteCarouseClick(index)"
                  ></i>
                </div>
              </draggable>

              <el-upload
                :limit="5"
                action="#"
                list-type="picture-card"
                :before-upload="($event) => beforeSwiperUpload($event)"
              >
                <div slot="default">
                  <i
                    class="el-icon-camera-solid"
                    style="font-size: 40px; color: #999"
                  ></i>
                </div>

                <div slot="file" slot-scope="{ file }" @click="uploadBtn"></div>
              </el-upload>
            </div>
          </div>
          <!-- 门店信息 -->
          <div v-if="SelectComponent.Code === 'EntityInfo'">
            <div class="switchBox pad_0_10 dis_flex flex_y_center">
              <span class="font_14 color_333">门店信息</span>
            </div>
            <el-form class="pad_10">
              <el-form-item label="显示信息:">
                <el-checkbox-group
                  v-model="SelectComponent.Conf.EntityInfoConf"
                  class="dis_flex flex_dir_column"
                >
                  <el-checkbox label="Name">店铺名称</el-checkbox>
                  <el-checkbox label="Address">地址</el-checkbox>
                  <el-checkbox label="Phone">服务电话</el-checkbox>
                  <el-checkbox label="Describe">门店简介</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
          <!-- 热门推荐 -->
          <div v-if="SelectComponent.Code === 'GoodsList'">
            <div
              class="switchBox pad_0_10 dis_flex flex_y_center flex_x_between"
            >
              <div class="font_14 color_333">热门推荐</div>

              <el-dropdown
                szie="small"
                class="martp_10 marlt_10"
                style="right: 10px"
              >
                <el-button size="small" type="primary">选择商品</el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    @click.native="addGoods('project', '', true)"
                    >项目</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="addGoods('product', '', true)"
                    >产品</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="addGoods('generalCard', '', true)"
                    >通用次卡</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="addGoods('timeCard', '', true)"
                    >时效卡</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="addGoods('saveCard', '', true)"
                    >储值卡</el-dropdown-item
                  >
                  <el-dropdown-item
                    @click.native="addGoods('packageCard', '', true)"
                    >套餐卡</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <!-- v-if="SelectComponent.Conf.HotGoodsConf.GoodsList && SelectComponent.Conf.HotGoodsConf.GoodsList.length > 0"  -->
            <el-table
              :data="SelectComponent.Conf.HotGoodsConf.GoodsList"
              size="small"
            >
              <el-table-column prop="Name" label="商品"> </el-table-column>
              <el-table-column prop="GoodsTypeName" label="商品类型">
              </el-table-column>
              <el-table-column prop="Price" label="售价（元）">
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    size="small"
                    type="danger"
                    @click="deleteHotGoodsItemClick(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-form class="pad_10">
              <el-form-item label="显示信息:">
                <el-checkbox-group
                  v-model="SelectComponent.Conf.HotGoodsConf.Conf"
                  class="dis_flex flex_dir_column"
                >
                  <el-checkbox label="Name">服务名称</el-checkbox>
                  <el-checkbox label="Price">服务价格</el-checkbox>
                  <el-checkbox label="OriginalText">划线价格</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-form>
          </div>
          <!-- 空白元素 -->
          <div v-if="SelectComponent.Code === 'AuxiliaryBlank'">
            <div class="switchBox pad_0_10 dis_flex flex_y_center">
              <span class="font_14 color_333">辅助空白</span>
            </div>
            <div class="dis_flex flex_dir_row flex_y_center">
              <el-slider
                v-model="SelectComponent.Conf.AuxiliaryBlank.Height"
                :min="blank_mini"
                style="width: 70%"
                class="marlt_15"
              ></el-slider>
              <span class="font_12 color_666 marlt_15"
                >{{ SelectComponent.Conf.AuxiliaryBlank.Height }} 像素</span
              >
            </div>
            <el-form class="pad_10">
              <el-form-item label="背景颜色:">
                <div class="dis_flex flex_dir_column customerColor">
                  <el-radio-group class="martp_15" v-model="blank_colorRadio">
                    <el-radio label="1">默认</el-radio>
                    <el-radio label="2">自定义</el-radio>
                  </el-radio-group>
                  <div
                    v-if="blank_colorRadio == '2'"
                    class="dis_flex flex_y_center martp_15"
                  >
                    <el-color-picker
                      size="medium"
                      style="width: 50px"
                      v-model="SelectComponent.Conf.AuxiliaryBlank.Color"
                      :predefine="predefineColors"
                    ></el-color-picker>
                    <el-button
                      style="margin-left: 40px"
                      size="small"
                      type="primary"
                      @click="resetColor"
                      >重 置</el-button
                    >
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
          <!-- 分割线 -->
          <div v-if="SelectComponent.Code === 'AuxiliaryLine'">
            <div class="switchBox pad_0_10 dis_flex flex_y_center">
              <span class="font_14 color_333">辅助线段</span>
            </div>

            <el-form class="pad_10">
              <el-form-item label="颜色:">
                <div class="dis_flex flex_y_center martp_10">
                  <el-color-picker
                    size="medium"
                    style="width: 50px"
                    v-model="SelectComponent.Conf.AuxiliaryLine.Color"
                    :predefine="predefineColors"
                  ></el-color-picker>
                  <el-button
                    style="margin-left: 40px"
                    size="small"
                    type="primary"
                    @click="resetColor"
                    >重 置</el-button
                  >
                </div>
              </el-form-item>

              <el-form-item label="边距:">
                <el-radio-group
                  v-model="SelectComponent.Conf.AuxiliaryLine.isPadding"
                >
                  <el-radio :label="false">无边距</el-radio>
                  <el-radio :label="true">左右留边</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="样式:">
                <el-radio-group
                  v-model="SelectComponent.Conf.AuxiliaryLine.borderStyle"
                >
                  <el-radio label="solid">实线</el-radio>
                  <el-radio label="dashed">虚线</el-radio>
                  <el-radio label="dotted">点线</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-form>
          </div>
          <!-- 富文本 -->
          <div v-if="SelectComponent.Code === 'RichText'">
            <div class="switchBox pad_0_10 dis_flex flex_y_center">
              <span class="font_14 color_333">富文本</span>
            </div>
            <input
              type="file"
              style="display: none"
              id="input"
              @change.prevent="RichTextUpLoadImg()"
            />
            <quill-editor
              ref="myQuillEditor"
              v-model="SelectComponent.Conf.RichText"
              :options="editorOption"
              style="width: 100%; height: 100%"
            ></quill-editor>
          </div>
        </el-scrollbar>
      </div>
    </div>

    <!-- 商品分类添加商品弹出层 -->
    <el-dialog
      :title="goodsClassifyTitle"
      :visible.sync="goodsClassifyBool"
      @close="goodsDialogClose"
      width="60%"
    >
      <!-- 搜索框 -->
      <el-row>
        <el-col :span="22">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            label-width="40px"
            @submit.native.prevent
          >
            <el-form-item label="名称">
              <el-input
                v-model="goodsSearchName"
                @clear="SearchGoods"
                clearable
                placeholder="输入商品线上名称搜索"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="SearchGoods"
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <!-- 表格 -->

      <el-table
        ref="HotGoodsRef"
        :row-key="(row) => row.ID"
        :data="GoodList"
        tooltip-effect="dark"
        height="55vh"
        style="width: 100%"
        @current-change="handleCurrentChange"
        @selection-change="hotGoodsSelectionChange"
        :highlight-current-row="!isAddHotGoods"
      >
        <el-table-column
          v-if="isAddHotGoods"
          :reserve-selection="true"
          width="55"
          type="selection"
        ></el-table-column>
        <el-table-column label="主图缩略">
          <template slot-scope="scope">
            <el-image
              style="width: 35px; height: 35px"
              :src="scope.row.ImageURL"
              fit="cover"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="Name" label="商品名称"></el-table-column>
        <el-table-column prop="Price" label="售价（元）"></el-table-column>
        <el-table-column
          v-if="
            goodsClassifyTitle == '项目列表' || goodsClassifyTitle == '产品列表'
          "
          prop="OriginalText"
          label="划线价（元）"
        ></el-table-column>
        <el-table-column
          v-if="goodsClassifyTitle == '通用次卡列表'"
          prop="Amount"
          label="次数"
        ></el-table-column>
        <el-table-column
          v-if="goodsClassifyTitle == '时效卡列表'"
          prop="ConsumeCycle"
          :formatter="formatterTimeCardDay"
          label="消耗周期(天)"
        ></el-table-column>
        <el-table-column
          v-if="goodsClassifyTitle == '时效卡列表'"
          prop="CycleLimitAmount"
          :formatter="formatterTimeCardNum"
          label="周期次数"
        ></el-table-column>
        <el-table-column
          v-if="goodsClassifyTitle == '时效卡列表'"
          prop="PerformanceAmount"
          label="消耗提成次数"
        ></el-table-column>
        <el-table-column
          v-if="goodsClassifyTitle == '储值卡列表'"
          prop="LargessPrice"
          label="赠送金额"
        ></el-table-column>
        <el-table-column
          v-if="
            goodsClassifyTitle != '项目列表' && goodsClassifyTitle != '产品列表'
          "
          prop="ValidDayName"
          label="有效期"
        ></el-table-column>
        <el-table-column
          prop="GoodsTypeName"
          label="商品类型"
        ></el-table-column>
      </el-table>

      <!-- 页码 -->
      <div class="pad_10 dis_flex flex_x_end">
        <el-pagination
          background
          :current-page.sync="paginations.page"
          :layout="paginations.layout"
          :total="paginations.total"
          @current-change="pageChange"
        ></el-pagination>
      </div>
      <!-- 底部 -->
      <span v-if="isAddHotGoods" slot="footer" class="dialog-footer">
        <el-button size="small" @click="goodsClassifyBool = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="saveHotGoods"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HomeConf from "./HomeConf";
import draggable from "vuedraggable";
import API from "@/api/Marketing/EShopManage/EShopDecoration";
import utils from "@/components/js/utils.js";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "HomeDecoration",
  props: {},
  /**  引入的组件  */
  components: {
    draggable,
    quillEditor,
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      loading: false,
      comps: [],
      HomeConf: HomeConf,
      SelectComponent: {
        Conf: {
          Carousel_file_list: [],
          EntityInfoConf: ["Name", "Address", "Phone", "Describe"],
          AuxiliaryBlank: { Height: 15, Color: "#FFFFFF" },
          AuxiliaryLine: {
            isPadding: false,
            Color: "#eeeeee",
            borderStyle: "solid",
          },
          HotGoodsConf: {
            GoodsList: [],
            Conf: ["Name", "Price", "OriginalText"],
          },
          RichText: "",
        },
      },
      ComponentData: {},
      EntityName: "",
      paginations: {
        total: 0,
        layout: " prev, pager, next, jumper, ->, total, slot",
        page: 1,
      },
      CarouselIndex: "",
      goodsSearchName: "",
      GoodList: [], //商品列表
      goodsClassifyBool: false, //商品列表弹出层
      goodsClassifyTitle: "", //商品列表弹出层标题
      apiType: "", // 请求类型
      isAddHotGoods: false,
      goodsType: "",
      tempSelectionHotGoods: [],
      blank_colorRadio: "1",
      blank_mini: 15,
      predefineColors: [
        "#ff4500",
        "#ff8c00",
        "#ffd700",
        "#90ee90",
        "#00ced1",
        "#1e90ff",
        "#c71585",
        "#FED439",
        "#A9DEB0",
        "#00BABC",
        "#39A2ED",
        "#FF001D",
      ],
      editorOption: "",
    };
  },
  /**计算属性  */
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  /**  方法集合  */
  methods: {
    /**  上传 门店照片  */
    beforeSwiperUpload(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        that.$message.error("上传头像图片大小不能超过 3MB!");
        return false;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        that.$nextTick(function () {
          that.SelectComponent.Conf.Carousel_file_list.push({
            url: base64,
            goodsID: "",
            goodsName: "",
            GoodsType: "",
            GoodsTypeName: "",
          });
        });
      };
      return false;
    },
    /**  轮播图添加 商品  */
    addGoods(type, index, isAddHotGoods) {
      let that = this;
      that.paginations.page = 1;
      that.isAddHotGoods = isAddHotGoods;
      that.goodsType = type;
      that.tempSelectionHotGoods = [];
      let typeCode = "";
      switch (type) {
        case "project":
          that.apiType = "GoodsCategoryProject";
          that.goodsClassifyTitle = "项目列表";
          typeCode = "20";
          break;
        case "product":
          that.apiType = "GoodsCategoryProduct";
          that.goodsClassifyTitle = "产品列表";
          typeCode = "10";
          break;
        case "generalCard":
          that.apiType = "GoodsCategoryGeneralCard";
          that.goodsClassifyTitle = "通用次卡列表";
          typeCode = "30";
          break;
        case "timeCard":
          that.apiType = "GoodsCategoryTimeCard";
          that.goodsClassifyTitle = "时效卡列表";
          typeCode = "40";
          break;
        case "saveCard":
          that.apiType = "GoodsCategorySavingCard";
          that.goodsClassifyTitle = "储值卡列表";
          typeCode = "50";
          break;
        case "packageCard":
          that.apiType = "GoodsCategoryPackageCard";
          that.goodsClassifyTitle = "套餐卡列表";
          typeCode = "60";
          break;
      }
      that.CarouselIndex = index;
      that.getGoodAll(that.apiType);
      that.goodsClassifyBool = true;

      that.$nextTick(() => {
        that.$refs.HotGoodsRef.clearSelection();
        console.log(that.SelectComponent.Conf.HotGoodsConf);
        if (that.SelectComponent.Conf.HotGoodsConf.GoodsList.length) {
          that.SelectComponent.Conf.HotGoodsConf.GoodsList.forEach((row) => {
            if (row.GoodsType == typeCode) {
              that.$refs.HotGoodsRef.toggleRowSelection(row);
            }
          });
        }
      });
    },

    formatterTimeCardDay(row) {
      if (row.ConsumeCycle == 0 || row.CycleLimitAmount == 0) {
        return "不限制";
      } else {
        return row.ConsumeCycle;
      }
    },
    formatterTimeCardNum(row) {
      if (row.ConsumeCycle == 0 || row.CycleLimitAmount == 0) {
        return "不限制";
      } else {
        return row.CycleLimitAmount;
      }
    },
    /**  关闭商品弹窗  */
    goodsDialogClose() {
      let that = this;
    },
    /** 修改商品分页   */
    pageChange(page) {
      let that = this;
      that.paginations.page = page;
      that.getGoodAll(that.apiType);
    },
    /**  选择商品  */
    handleCurrentChange(row) {
      let that = this;
      if (that.isAddHotGoods) {
        that.$refs.HotGoodsRef.toggleRowSelection(row);
      } else {
        that.SelectComponent.Conf.Carousel_file_list[
          that.CarouselIndex
        ].goodsID = row.ID;
        that.SelectComponent.Conf.Carousel_file_list[
          that.CarouselIndex
        ].goodsName = row.Name;
        that.SelectComponent.Conf.Carousel_file_list[
          that.CarouselIndex
        ].GoodsType = row.GoodsType;
        that.SelectComponent.Conf.Carousel_file_list[
          that.CarouselIndex
        ].GoodsTypeName = row.GoodsTypeName;

        that.goodsClassifyBool = false;
      }
    },
    /**  删除 链接的商品  */
    deleteSwiperGoods() {
      let that = this;
      that.SelectComponent.Conf.Carousel_file_list[that.CarouselIndex].goodsID =
        "";
      that.SelectComponent.Conf.Carousel_file_list[
        that.CarouselIndex
      ].goodsName = "";
      that.SelectComponent.Conf.Carousel_file_list[
        that.CarouselIndex
      ].GoodsType = "";
      that.SelectComponent.Conf.Carousel_file_list[
        that.CarouselIndex
      ].GoodsTypeName = "";
    },
    /**  删除轮播图 中的item  */
    deleteCarouseClick(indx) {
      let that = this;
      that.SelectComponent.Conf.Carousel_file_list.splice(indx, 1);
    },

    // 点击推荐
    recommend() {
      const that = this;
      that.RmDialog = true;
      that.Title = "商品列表";
      that.getGoodAll();
    },
    /**  鼠标移入组件  */
    compontenMouse(comp) {
      let that = this;
      for (let item of that.comps) {
        item.isShowBoder = false;
      }
      comp.isShowBoder = true;
    },
    /**  鼠标移出 组件  */
    compontenMouseLeave(comp) {
      comp.isShowBoder = false;
    },
    /** 选中 组件   */
    selectComponentClick(comp) {
      let that = this;
      for (let item of that.comps) {
        item.isEdit = false;
      }
      comp.isEdit = true;
      that.SelectComponent = comp;
    },
    /**  添加组件  */
    addComponent(item) {
      let that = this;
      for (let item of that.comps) {
        item.isEdit = false;
      }
      let comp = {
        Code: item.Code,
        Name: item.Name,
        Sequence: item.Sequence,
        isEdit: true,
        isShowBoder: false,
        Conf: {
          Carousel_file_list: [],
          EntityInfoConf: ["Name", "Address", "Phone", "Describe"],
          AuxiliaryBlank: { Height: 15, Color: "#FFFFFF" },
          AuxiliaryLine: {
            isPadding: false,
            Color: "#eeeeee",
            borderStyle: "solid",
          },
          HotGoodsConf: {
            GoodsList: [],
            Conf: ["Name", "Price", "OriginalText"],
          },
          RichText: "",
        },
      };
      that.comps.push(comp);
      that.SelectComponent = comp;
    },
    /**  删除已添加的组件  */
    deleteComponent(indx) {
      let that = this;
      that.comps.splice(indx, 1);
      that.SelectComponent.Code = "null";
    },

    /**    */
    editStyle() {
      return {
        border: "2px dotted #07bcfd",
      };
    },

    /**  门店名称  */
    getEntityName(val) {
      let that = this;
      that.EntityName = val;
    },
    /** 热门商品选项发生改变时  */
    hotGoodsSelectionChange(selection) {
      let that = this;
      that.tempSelectionHotGoods = selection;
    },
    /**  保存热门推荐商品  */
    saveHotGoods() {
      let that = this;
      that.goodsClassifyBool = false;
      console.log(that.SelectComponent.Conf.HotGoodsConf);
      let tempArr = that.tempSelectionHotGoods.filter((val) => {
        let typeGoods = that.SelectComponent.Conf.HotGoodsConf.GoodsList.filter(
          (item) => val.GoodsType == item.GoodsType
        );
        let is = typeGoods.some((v) => v.ID == val.ID);
        if (!is) return val;
      });
      that.SelectComponent.Conf.HotGoodsConf.GoodsList.push.apply(
        that.SelectComponent.Conf.HotGoodsConf.GoodsList,
        tempArr
      );
    },
    /**    */
    deleteHotGoodsItemClick(indx) {
      let that = this;
      that.SelectComponent.Conf.HotGoodsConf.GoodsList.splice(indx, 1);
    },
    /**  重置 空白元素颜色  */
    resetColor() {
      let that = this;
      that.SelectComponent.Conf.AuxiliaryBlank.Color = "#FFFFFF";
    },
    /**  搜索商品  */
    SearchGoods() {
      let that = this;
      that.paginations.page = 1;
      that.getGoodAll(that.apiType);
    },

    /** 网络请求  ****************************************  */
    // 获取分类商品列表
    getGoodAll(apiType) {
      const that = this;
      const params = {
        PageNum: that.paginations.page,
        Name: that.goodsSearchName,
      };
      API[apiType](params).then((res) => {
        if (res.StateCode == 200) {
          that.GoodList = res.List;
          that.paginations.total = res.Total;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    /**  请求组件  */
    getComponents() {
      let that = this;
      let params = {};
      API.getComponents(params).then((res) => {
        if (res.StateCode == 200) {
          that.ComponentData = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    /** 保存首页配置   */
    HomeConfcreate() {
      let that = this;
      let HomeConfList = that.comps.map((val, indx) => {
        let Component = {
          ComponentsCode: val.Code,
          ComponentsProperty: that.getComponentProperty(val),
          Sequence: indx,
        };
        return Component;
      });
      let params = { HomeConfList: HomeConfList };
      that.loading = true;
      API.HomeConfcreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "保存成功",
              duration: 2000,
            });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.loading = false;
        });
    },
    /**  获取 组件属性  */
    getComponentProperty(item) {
      switch (item.Code) {
        case "RichText": // 富文本
          return JSON.stringify(item.Conf.RichText);
        case "Carousel": // 轮播图
          return JSON.stringify(item.Conf.Carousel_file_list);
        case "GoodsList": // 热门商品推荐
          return JSON.stringify(item.Conf.HotGoodsConf);
        case "EntityInfo": // 门店信息
          return JSON.stringify(item.Conf.EntityInfoConf);
        case "AuxiliaryBlank": // 辅助空白
          return JSON.stringify(item.Conf.AuxiliaryBlank);
        case "AuxiliaryLine": // 辅助线段
          return JSON.stringify(item.Conf.AuxiliaryLine);
        default:
          return "";
      }
    },
    /**  获取首页配置  */
    getHomeConf() {
      let that = this;
      API.getHomeConf().then((res) => {
        if (res.StateCode == 200) {
          that.comps = res.Data.map((val) => {
            return that.getComponentPropertyJSON(val);
          });
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },

    /**  获取 组件属性  */
    getComponentPropertyJSON(val) {
      let comp = {
        Code: val.ComponentsCode,
        Name: val.ComponentsCode,
        Sequence: val.Sequence,
        isEdit: false,
        isShowBoder: false,
        Conf: {
          Carousel_file_list: [],
          EntityInfoConf: ["Name", "Address", "Phone", "Describe"],
          AuxiliaryBlank: { Height: 15, Color: "#FFFFFF" },
          AuxiliaryLine: {
            isPadding: false,
            Color: "#eeeeee",
            borderStyle: "solid",
          },
          HotGoodsConf: {
            GoodsList: [],
            Conf: ["Name", "Price", "OriginalText"],
          },
          RichText: "",
        },
      };

      switch (val.ComponentsCode) {
        case "RichText": {
          // 富文本
          comp.Conf.RichText = JSON.parse(val.ComponentsProperty);
          break;
        }
        case "Carousel": {
          // 轮播图
          let CarouselProperty = JSON.parse(val.ComponentsProperty);
          for (let item of CarouselProperty) {
            utils.getCanvasBase64(item.url).then(
              function (base64) {
                item.url = base64;
              },
              function (err) {
                console.log(err);
              }
            );
          }
          comp.Conf.Carousel_file_list = CarouselProperty;
          break;
        }
        case "GoodsList": {
          // 热门商品推荐
          let HotGoodsConfProperty = JSON.parse(val.ComponentsProperty);
          if (typeof HotGoodsConfProperty.Conf == "undefined") {
            HotGoodsConfProperty.Conf = [];
          }
          comp.Conf.HotGoodsConf = HotGoodsConfProperty;
          break;
        }
        case "EntityInfo": {
          // 门店信息
          let EntityInfoConfProperty = JSON.parse(val.ComponentsProperty);
          comp.Conf.EntityInfoConf = EntityInfoConfProperty;

          break;
        }
        case "AuxiliaryBlank": {
          // 辅助空白
          let AuxiliaryBlankProperty = JSON.parse(val.ComponentsProperty);
          comp.Conf.AuxiliaryBlank = AuxiliaryBlankProperty;

          break;
        }
        case "AuxiliaryLine": {
          // 辅助线段
          let AuxiliaryLineProperty = JSON.parse(val.ComponentsProperty);
          comp.Conf.AuxiliaryLine = AuxiliaryLineProperty;
          break;
        }
        default:
          break;
      }
      return comp;
    },
    /**   富文本上传图片 */
    RichTextUpLoadImg() {
      let that = this;

      let files = window.event.target.files[0];
      if (files.size / 1024 / 1024 > 1) {
        // 定义上传的图片的大小
        that.$message.error({ message: "请上传小于1M的图片", duration: 2000 });
        return;
      }
      let fileType = [".jpg", ".png", ".jpeg"];
      let suffix = files.name.substring(
        files.name.lastIndexOf("."),
        files.name.length
      );
      if (fileType.indexOf(suffix) === -1) {
        that.$message.warning("请上传格式为png、jpg的文件");
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function (evt) {
        let base64 = evt.target.result;
        // 获取光标所在位置
        let length = that.editor.getSelection().index;
        // 插入图片  res.info为服务器返回的图片地址
        that.editor.insertEmbed(length, "image", base64);
        // 调整光标到最后
        that.editor.setSelection(length + 1);
      };
    },
    /**  监听富文本 内容变化  */
    // onEditorChange({ html }) {
    //   let that = this;
    //   const regex = new RegExp("<img", "gi");
    //   that.SelectComponent.Conf.RichText = html.replace(regex, `<img style="max-width: 100%;"`);
    // },
    /**   富文本 配置 */
    QuillEditorOptions() {
      let that = this;
      //  富文本编辑器配置
      var toolbarOptions = [
        ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
        ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
        [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
        [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
        [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
        [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
        [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
        [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
        [{ font: [] }], // 字体种类-----[{ font: [] }]
        [{ align: [] }], // 对齐方式-----[{ align: [] }]
        ["clean"], // 清除文本格式-----['clean']
        ["image"],
      ]; // 链接、图片、视频-----['link', 'image', 'video']

      that.editorOption = {
        modules: {
          //工具栏定义的
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (val) {
                if (val) {
                  // 通过input的type=file唤醒选择弹框，选择之后自定义上传路径
                  document.querySelector("#input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
        },
        //主题
        theme: "snow",
        placeholder: "请输入正文",
      };
    },

    //
  },
  /** 监听数据变化   */
  watch: {},

  /**  实例创建完成之后  */
  created() {
    let that = this;
    that.QuillEditorOptions();
    that.EntityName = JSON.parse(
      localStorage.getItem("access-user")
    ).EntityName;
    that.getComponents();
    that.getHomeConf();
  },
  /**  实例被挂载后调用  */
  mounted() {},
};
</script>

<style lang="scss">
.HomeDecoration {
  .swiperGoods {
    padding: 3px;
    border-radius: 3px;
    background-color: #ebf5fe;
  }
  .swiperImage {
    width: 225px;
    height: 100px;
    overflow: hidden;
    border: 1px solid #c0ccda;
    border-radius: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 8px 0px 0;
    display: inline-block;
  }
  .customerColor {
    .el-color-picker__trigger {
      width: 70px;
    }
  }

  .homemodel {
    position: relative;
    width: 375px;
    height: 300px;
    background-color: #fafafa !important;
    margin: 0 auto;
    .rmbox {
      width: 172px;
      background: #ffffff;
      border-radius: 5px;
      margin: 10px 0;
      overflow: hidden;
      box-sizing: border-box;
      .labelTag {
        display: inline-block;
        background: linear-gradient(-90deg, #ff5510 0%, #ff8f61 100%);
        color: #fff;
        border-radius: 3px;
        font-size: 11px;
        padding: 0px 3px;
        margin: 2px 5px 0 0;
        box-sizing: border-box;
        line-height: 18px;
      }
      .coupon {
        position: relative;
        display: inline-block;
        background-color: #fff6f3;
        overflow: hidden;
        color: #ff5510;
        box-sizing: border-box;
      }
      .coupon span:nth-child(1) {
        display: inline-block;
        height: 20px;
        line-height: 19px;
        padding: 0 5px;
        border-left: 1px solid rgba(255, 85, 16, 0.4);
        border-top: 1px solid rgba(255, 85, 16, 0.4);
        border-bottom: 1px solid rgba(255, 85, 16, 0.4);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        box-sizing: border-box;
      }
      .coupon span:nth-child(2) {
        display: inline-block;
        height: 20px;
        line-height: 19px;
        padding: 0 5px 0 10px;
        border-right: 1px solid rgba(255, 85, 16, 0.4);
        border-top: 1px solid rgba(255, 85, 16, 0.4);
        border-bottom: 1px solid rgba(255, 85, 16, 0.4);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        box-sizing: border-box;
      }
      .coupon span:nth-child(3) {
        position: absolute;
        top: -3px;
        left: 18px;
        width: 6px;
        height: 6px;
        border: 1px solid rgba(255, 85, 16, 0.4);
        border-radius: 50%;
        background-color: #fff;
        z-index: 1111;
      }
      .coupon span:nth-child(4) {
        position: absolute;
        bottom: -3px;
        left: 18px;
        width: 6px;
        height: 6px;
        border: 1px solid rgba(255, 85, 16, 0.4);
        border-radius: 50%;
        background-color: #fff;
        z-index: 1111;
      }
    }
    .logo {
      position: absolute;
      right: 30px;
      bottom: -10px;
    }
    .search-top {
      width: 355px;
      height: 34px;
      border-radius: 17px;
      background-color: #eeeeee;
      line-height: 34px;
    }
    // .line::before {
    //   content: "";
    //   display: inline-block;
    //   width: 1px;
    //   height: 15px;
    //   background-color: #999;
    //   margin-right: 15px;
    // }
  }
  // border-radius: ;
}
</style>
